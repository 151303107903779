import React, { useState,useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Link, useLocation } from 'react-router-dom';
import { Stack ,Icon} from '@mui/material';
import { Drawer } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WebhookIcon from '@mui/icons-material/Webhook';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


import {Snackbar} from "@mui/material"
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BookIcon from '@mui/icons-material/Book';
const pages = [
    {
        title: 'Products',
        dropdownlist: [
            { name: 'ERP', link: '/erp',icon:<WebhookIcon /> },           
            { name: 'CRM', link: '/crm', icon: <FactCheckOutlinedIcon /> },
            { name: 'HRMS', link: '/hrms' ,icon:<AssignmentIcon /> },            
            { name: 'Health-care', link: '/health-care', icon: <HealthAndSafetyIcon /> },
            { name: 'Assets', link: '/assets',icon:<AssessmentIcon /> },
        ]
    }, 
    { title: 'Pricing', link: '/pricing' }, 
    {
        title: 'Company',
        dropdownlist: [
            { name: 'About Us', link: '/about', icon: <FactCheckOutlinedIcon /> },
            { name: 'Contact Us', link: '/contactus',icon:<ContactPageIcon /> },           
            { name: 'Blog', link: '/blog', icon: <BookIcon /> },
           
           
        ]
    }, 

];


const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [dropdownAnchor, setDropdownAnchor] = useState(null);
    const [isScrolled, setScrolled] = useState(false);
    const [productsDropdownOpen, setProductsDropdownOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(null);

    const [scrollingup, setScrollingUp] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        if (window.scrollY < lastScrollY) {
            setScrollingUp(true);
        } else if (window.scrollY > lastScrollY) {
            setScrollingUp(false);
        }
        setLastScrollY(window.scrollY);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
    })
    
    
    const location = useLocation()
    const isHomePage = location.pathname === "/"

    const [open,setOpen] = useState(false);

    const handleClickOpen = () =>{
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setProductsDropdownOpen(false);
        setDropdownOpen(null);
    };

    const handleDropdownOpen = (event,pageTitle) => {
        setDropdownAnchor(event.currentTarget);
        setDropdownOpen(dropdownOpen === pageTitle ? null : pageTitle);
    };

    const handleDropdownClose = () => {
        setDropdownOpen(null);
        setDropdownAnchor(null);
    };
    const [activeDropdown, setActiveDropdown] = React.useState(null); // Tracks the currently open dropdown

    const toggleDropdown = (pageTitle) => {
        setActiveDropdown((prev) => (prev === pageTitle ? null : pageTitle));
    };
    
    
    useEffect(() => {
        if (isHomePage) {
            const handleScroll = () => { 
                setScrolled(window.scroll > 1);
            }
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            }
        } else {
            setScrolled(false);
        }    
    }, [isHomePage])
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState(''); 

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        } else {
            setSnackbarOpen(false);
        }
    }
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phonenumber: '',
        mobileNo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try { 
            const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
                cache: 'no-cache',
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error("Network Response was not ok")
            }

            const result = await response.json()
            if (result.message) {
                if (result.message.message.success === "Email ID added successfully") {
                setSnackbarMessage("Email ID added successfully");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);

                } else if (result.message && result.message.message && result.message.message.error) {
                let errorMessage = result.message.message.error;
                setSnackbarSeverity(errorMessage === 'Email ID already exists' ? 'warning' : 'error');
                setSnackbarTitle(errorMessage === 'Email ID already exists' ? 'Warning' : 'Error');
                setSnackbarMessage(errorMessage === 'Email ID already exists' ? 'You already requested, please check your email.' : errorMessage);
                setSnackbarOpen(true);
                }
            }
        } catch (error) {
            setSnackbarMessage('An error occurred. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarTitle('Error');
            setSnackbarOpen(true);
        }
    }

    return (
        <>
            <AppBar
                elevation={0}
                position={scrollingup ? "fixed" : 'static'}
                    sx={{
                        top: 0,
                        width: '100%',
                        backgroundColor: scrollingup ? '#FFFEFA' : 'white',
                        backdropFilter: scrollingup ? 'blur(10px)' : 'none',
                        color: 'black',
                        transition: 'top 0.3s ease, background-color 0.3s ease, backdrop-filter 0.3s ease',
                        background: scrollingup ? 'rgba(255, 255, 255, 0.8)' : 'none',
                    }}
                >
                <Container maxWidth="xl">
                    <Toolbar>
                        {/* Logo for larger screens */}
                        <Typography
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            ml: { xs: 1, md: 0 },
                            mr: { xs: 1, md: 5 },
                            display: 'flex',
                            fontFamily: 'robot',
                            fontWeight: 'bold',
                           
                            color: '#12715B',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            fontSize: '25px',
                            width: '150px',
                            textAlign: 'left',
                        }}
                        >
                            FusionERP
                        </Typography>

                        <Box
                            sx={{ 
                                flexGrow: 1,
                                display: { xs: 'flex', md: 'none' },
                                justifyContent: 'flex-end',
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>

                            <Drawer
                                anchor="top"
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    width: '100%',
                                    maxWidth: '100vw',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 10,
                                    backgroundColor: 'white',
                                    p: 2,
                                    
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        m: 2.5,
                                        
                                    }}
                                    onClick={handleCloseNavMenu}
                                >
                                    <ClearIcon
                                        sx={{
                                            mb:2,
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: "rotate(180deg)",
                                            },
                                        }}
                                    />
                                </Box>


                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        gap: 2,
                                        width: '100%',
                                    }}
                                >
                                    {pages.map((page) => (
                                        <Box key={page.title} sx={{ width: '100%' }}>
                                            {page.dropdownlist ? (
                                                <>
                                                    <MenuItem
                                                        onClick={() => toggleDropdown(page.title)}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            '&:hover': {
                                                                backgroundColor: '#f4f4f4',
                                                            },
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: 'black',
                                                                textDecoration: 'none',
                                                                cursor: 'pointer',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {page.title}
                                                        </Typography>
                                                        {activeDropdown === page.title ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </MenuItem>

                                                    {/* Dropdown items */}
                                                    {activeDropdown === page.title &&
                                                        page.dropdownlist.map((item) => (
                                                            <MenuItem
                                                                key={item.name}
                                                                onClick={handleCloseNavMenu}
                                                                sx={{
                                                                    width: '100%',
                                                                    '&:hover': {
                                                                        backgroundColor: '#f0f0f0',
                                                                    },
                                                                }}
                                                            >
                                                                <Typography
                                                                    component={Link}
                                                                    to={item.link}
                                                                    sx={{
                                                                        color: 'black',
                                                                        textDecoration: 'none',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: 2,
                                                                    }}
                                                                >
                                                                    <span style={{ color: '#12715B' }}>{item.icon}</span>
                                                                    {item.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))}
                                                </>
                                            ) : (
                                                <MenuItem
                                                    onClick={handleCloseNavMenu}
                                                    sx={{
                                                        width: '100%',
                                                        '&:hover': {
                                                            backgroundColor: '#f4f4f4',
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        component={Link}
                                                        to={page.link}
                                                        sx={{ color: 'black', textDecoration: 'none' }}
                                                    >
                                                        {page.title}
                                                    </Typography>
                                                </MenuItem>
                                            )}
                                        </Box>
                                    ))}

                                    {/* Buttons */}
                                    <Button
                                        sx={{
                                            color: 'black',
                                            textTransform: 'none',
                                            justifyContent: 'flex-start',
                                            ml: 1,
                                            fontSize: '16px',
                                            '&:hover': {
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                            },
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        Request a demo
                                    </Button>
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        component={Link}
                                        to="/signup"
                                        sx={{
                                            color: 'black',
                                            textTransform: 'none',
                                            justifyContent: 'flex-start',
                                            ml: 1,
                                            fontSize: '16px',
                                            '&:hover': {
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        Signup
                                    </Button>
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#12715B',
                                            color: 'white',
                                            padding: '10px 20px',
                                            justifyContent: 'flex-start',
                                            ml: 1,
                                            mb: 5,
                                            '&:hover': {
                                                backgroundColor: '#0f5746',
                                            },
                                        }}
                                    >
                                        <Typography
                                            component={Link}
                                            to="/pricing"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            Start free trial
                                        </Typography>
                                    </Button>
                                </Box>;
                            </Drawer>
                        </Box>


                        {/* Desktop Menu */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {pages.map((page) => (
                                        <Box key={page.title}>
                                            {page.dropdownlist ? (
                                                <>
                                                    <Button
                                                        onClick={(event) => handleDropdownOpen(event, page.title)}
                                                        component={Link}
                                                        to={page.link}
                                                        sx={{
                                                            
                                                            my: 2,
                                                            color: 'black',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexGrow: 0,
                                                            fontWeight: 'bold',
                                                            textTransform: 'none',
                                                            fontSize: '17px',
                                                            ml:5
                                                        }}
                                                        endIcon={dropdownOpen === page.title ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    >
                                                        {page.title}
                                                    </Button>
                                                    <Menu
                                                        anchorEl={dropdownAnchor}
                                                        open={dropdownOpen === page.title} 
                                                        onClose={handleDropdownClose}
                                                        MenuListProps={{ onMouseLeave: handleDropdownClose }}
                                                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                    >
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            sx={{ Width: "200px", ml: 2 }}
                                                        >
                                                            <Box
                                                                flex="1"
                                                                sx={{
                                                                    borderRight: "1px solid #e0e0e0",
                                                                }}
                                                            >
                                                                {page.dropdownlist.map((item, index) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        onClick={handleDropdownClose}
                                                                        component={Link} to={item.link}
                                                                        sx={{
                                                                            width: "90%",
                                                                            padding: '16px 12px ',
                                                                            overflow: 'visible',
                                                                            position: "relative",
                                                                            transition: "width 0.3s ease-in-out",
                                                                            borderRadius: '12px',

                                                                            "&:hover": {
                                                                                backgroundColor: "#f5f5f5",
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            gap={2}
                                                                            sx={{
                                                                                textWrap: 'wrap',
                                                                                width: "100%"
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                sx={{
                                                                                    color: "#12715B",
                                                                                    mb: 2,
                                                                                    fontSize: '17px',
                                                                                    overflow: 'visible'
                                                                                }}>{item.icon}</Icon>
                                                                            <Box>
                                                                                <Typography variant="body1" fontWeight="bold">
                                                                                    {item.name}
                                                                                </Typography>
                                                                                {item.name !== "Contact Us" && item.name !== "Blog" && item.name !== "About Us" && (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.secondary"
                                                                                        sx={{
                                                                                            fontSize: "12px",
                                                                                            textWrap: 'wrap',
                                                                                            whiteSpace: 'wrap'
                                                                                        }}
                                                                                    >
                                                                                        {`Manage your ${item.name.toUpperCase()} effectively.`}
                                                                                    </Typography>
                                                                                )}
                                                                            </Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                            </Box>

                                                            <Box
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                                sx={{
                                                                    flex: "1",
                                                                    background: "#fafafa",
                                                                }}
                                                            >
                                                               
                                                                    <Box
                                                                        sx={{
                                                                            textAlign: "center",
                                                                            justifyContent: "center",
                                                                            maxWidth: "220px",
                                                                            margin: "0 auto",
                                                                            padding: "16px",
                                                                            borderRadius: "8px",
                                                                            overflow: "hidden",
                                                                        }}
                                                                    >
                                                                            {page.dropdownlist.some(item => item.name !== "Contact Us" && item.name !== "Blog" && item.name !== "About Us") && (
                                                                                <Box
                                                                                    sx={{
                                                                                        position: "relative",
                                                                                        width: "100%",
                                                                                        height: "180px",
                                                                                        borderRadius: "8px",
                                                                                        overflow: "hidden",
                                                                                        marginBottom: "16px",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src="https://lexend.themegenix.com/four/wp-content/uploads/2024/08/login.webp"
                                                                                        alt="Everything you need is right at your fingertips"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            objectFit: "cover",
                                                                                        }}
                                                                                    />
                                                                                    {/* Play Button */}
                                                                                    <button
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            bottom: "8px",
                                                                                            left: "8px",
                                                                                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                                                                                            border: "none",
                                                                                            borderRadius: "50%",
                                                                                            width: "32px",
                                                                                            height: "32px",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "center",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                    >
                                                                                        <PlayArrowIcon sx={{ color: "#12715B" }} />
                                                                                    </button>
                                                                                    <a
                                                                                        href="https://themegenix.net/videos/lexend.webm"
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                            right: 0,
                                                                                            bottom: 0,
                                                                                        }}
                                                                                        data-caption="Everything you need is right at your fingertips"
                                                                                        role="button"
                                                                                        aria-label="Play video: Everything you need is right at your fingertips"
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                position: "absolute",
                                                                                                width: "1px",
                                                                                                height: "1px",
                                                                                                overflow: "hidden",
                                                                                                clip: "rect(0 0 0 0)",
                                                                                                whiteSpace: "nowrap",
                                                                                                border: "0",
                                                                                            }}
                                                                                        >
                                                                                            Play video: Everything you need is right at your fingertips
                                                                                        </span>
                                                                                    </a>
                                                                                </Box>
                                                                            )}
                                                                        {/* Content Box */}
                                                                        <Box>
                                                                            <Typography
                                                                                variant="h6"
                                                                                gutterBottom
                                                                                sx={{
                                                                                    fontWeight: "bold",
                                                                                    textAlign: "left",
                                                                                    whiteSpace: "wrap",
                                                                                    overflowWrap: "break-word",
                                                                                    lineHeight: 1.2,
                                                                                    fontSize: '15px'

                                                                                }}
                                                                            >
                                                                                Everything you need is right at your fingertips
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.secondary"
                                                                                gutterBottom
                                                                                sx={{
                                                                                    fontSize: "14.5px",
                                                                                    textAlign: "left",
                                                                                    opacity: 0.6,

                                                                                }}
                                                                            >
                                                                                Say goodbye to the hassle of managing multiple software and tools.
                                                                            </Typography>
                                                                            <Button
                                                                                variant="contained"
                                                                                href="/pricing"
                                                                                sx={{
                                                                                    mt: 2,
                                                                                    textTransform: "none",
                                                                                    backgroundColor: "#12715B",
                                                                                    color: "#fff",
                                                                                    "&:hover": {
                                                                                        backgroundColor: "#0e5e48",
                                                                                    },
                                                                                    fontSize: "14px",
                                                                                    padding: "8px 16px",
                                                                                    borderRadius: "8px",
                                                                                }}
                                                                            >
                                                                                Explore Pricing
                                                                            </Button>

                                                                        </Box>
                                                                    </Box>
                                                               
                                                            </Box>
                                                        </Box>
                                                    </Menu>
                                                </>
                                            ) : (
                                                <Button
                                                    onClick={handleCloseNavMenu}
                                                    component={Link}
                                                    to={page.link}
                                                    sx={{
                                                        my: 2,
                                                        color: 'black',
                                                        display: 'block',
                                                        textTransform: 'none',
                                                        ml: 5,
                                                        fontWeight: 'bold',
                                                        fontSize: '17px',
                                                        justifyContent:'space-between'
                                                    }}
                                                >
                                                    {page.title}
                                                </Button>
                                            )}
                                        </Box>
                                    ))}

                                <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end', 
                                    gap: 2,
                                    paddingRight: 2, 
                                }}
                                >
                                <Button sx={{ color: 'black', textTransform: 'none' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }} onClick={handleClickOpen}>
                                    Request a demo
                                    </Typography>
                                </Button>
                                <Button component={Link} to="/signup" sx={{ color: 'black', textTransform: 'none' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>Signup</Typography>
                                </Button>
                                <Button
                                    sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#12715B',
                                    color: 'white',
                                    padding: '10px 20px',
                                    }}
                                >
                                    <Typography
                                    component={Link}
                                    to="/pricing"
                                    sx={{
                                        fontSize: '17px',
                                        fontWeight: 'bold',
                                        color: 'white',
                                        textDecoration: 'none',
                                    }}
                                    >
                                    Start free trial
                                    </Typography>
                                </Button>
                                </Box>

                        </Box>
                                        
                    </Toolbar>
                </Container>
            </AppBar>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
            >
                <Box
                    sx={{
                        maxWidth: '100%',
                        padding: { xs: '15px', md: '30px' },
                        bgcolor: '#F5EEE9',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography
                        sx={{ textAlign: 'right', cursor: 'pointer' }}
                        onClick={handleClose}
                    >
                        <CloseIcon
                            fontSize="medium"
                            sx={{
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'rotate(180deg)',
                                },
                            }}
                        />
                    </Typography>
                    <DialogTitle
                        sx={{
                            textAlign: 'center',
                            mb: 2,
                            fontWeight: 'bold',
                            fontSize: { xs: '12px', md: '20px' },
                        }}
                    >
                        Schedule your 15-minute demo now
                    </DialogTitle>

                    {/* Form Fields */}
                    <TextField
                        color="success"
                        sx={{ mb: 2, mr: 6.8, width: '100%', backgroundColor: 'white' }}
                        required
                        placeholder="Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />

                    <OutlinedInput
                        color="success"
                        placeholder="Your email"
                        sx={{ mb: 2, mr: 6.8, width: '100%', backgroundColor: 'white' }}
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <OutlinedInput
                        color="success"
                        placeholder="Phone Number"
                        sx={{ mb: 2, width: '100%', backgroundColor: 'white' }}
                        required
                        name="phonenumber"
                        value={formData.phonenumber}
                        onChange={handleChange}
                    />

                    <OutlinedInput
                        color="success"
                        fullWidth
                        placeholder="Company Name"
                        sx={{ mb: 2, width: '100%', backgroundColor: 'white' }}
                        required
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                    />

                    <TextField
                        color="success"
                        fullWidth
                        placeholder="Your Message"
                        id="comment-box"
                        multiline
                        rows={5}
                        sx={{ mb: 2, backgroundColor: 'white' }}
                        required
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />

                    <DialogActions
                        sx={{
                            borderRadius: '5px',
                            mt: 2,
                            bgcolor: '#12715B',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            type="submit"
                            sx={{
                                color: 'white',
                                textTransform: 'none',
                                fontSize: '15px',
                                fontWeight: 'bold',
                            }}
                        >
                            Schedule my demo
                        </Button>
                    </DialogActions>
                </Box>
                <Stack
                    spacing={2}
                    sx={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: '#F5EEE9',
                    }}
                >
                    <Typography sx={{ p: 4, opacity: '60%' }}>
                        We’ll tailor your demo to your immediate needs and answer all your
                        questions. Get ready to see how it works!
                    </Typography>
                </Stack>
            </Dialog>


         <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </>
        
    );
};

export default Navbar;