import { Container, Grid2, Card, CardMedia, CardContent, Typography, Link ,Box,Button} from "@mui/material";
import React from 'react'

import image1 from '../Images/erp-blog.jpg';
import image2 from '../Images/blogcrm.avif';
import image3 from '../Images/BLOGHRMS.png';
import usericon from '../Images/Usericon.png';


function Profile() {

    const blogPosts = [
        {
          imgSrc:image1,          
          category: "ERP",
          title: "The transformative power of ERP in business operations",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Manjunath Sajjan",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          imgSrc:image2,
          category: "CRM",
          title: "The value of CRM in enhancing customer relationships",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Chris Thomas",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          imgSrc:image3,
          category: "HRMS",
          title: "Enhancing Workforce Management with ERPNext HRMS: A Complete Guide",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Sreesandhya Sajja",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
    ];

  return (
    <>
        <Container
            maxWidth={false}
            sx={{
                bgcolor: '#F5EEE9',
                padding: {xs:'20px',md:'50px'}                
            }}
        >
            <Box 
                sx={{ 
                    ml:{xs:0.1,md:3},
                    display: { xs: 'flex', md: 'flex' }, 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    justifyContent: { xs: 'flex-start', md: 'space-between' }, 
                    alignItems: { xs: 'flex-start', md: 'center' }, 
                    mt:{xs:'30px',md:0}
                }}
            >
                <Box>
                    <Typography sx={{
                        color: '#12715B',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        mb: 2
                    }}>
                        Our insights
                    </Typography>
                    <Typography sx={{
                        fontSize: {xs:'2em',md:'3em'},
                        lineHeight: '1.1em',
                        letterSpacing: '-0.12rem',
                        fontWeight: 'bold'
                    }}>
                        Latest posts and updates
                    </Typography>
                </Box>

                {/* Right side content */}
                <Box sx={{ mr: 4 }}>
                    <Button
                        href="/blog"
                        sx={{
                            padding:'10px 12px',
                            bgcolor:'#12715B',
                            textTransform:'none',
                            fontSize:'15px',
                            fontWeight:'bold',
                            color:'white',
                            borderRadius:'10px',
                            mt:{xs:'10px',md:0}
                        }}
                    >
                        View all posts
                    </Button>
                </Box>
            </Box>

            {/* Profile Section */}
            <Grid2
            container
            spacing={5}
            sx={{
                mt: 5,
                ml: { xs: 0, md: 1 },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center', 
                flexWrap: 'wrap',
                overflowX: 'auto',
            }}
            >
            {blogPosts.map((post, index) => (
                <Grid2
                item
                key={index}
                sx={{
                    flexShrink: 0,
                    width: { xs: '100%', sm: '380px' },
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                <Card
                    sx={{
                    height: 550,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '12px 12px 12px 12px',
                    }}
                >
                    <CardMedia
                    component="img"
                    height="300"
                    image={post.imgSrc}
                    alt={post.title}
                    loading="lazy"
                            sx={{
                                objectFit: 'cover',
                                '&:hover': {
                                    transform: 'scale(1.1)', 
                                    transition: 'transform 0.3s ease',
                                }
                    }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body2" fontWeight="bold">
                        <Link
                        href={`/blogpage/${post.category}`}
                        underline="hover"
                        sx={{ color: '#12715B', fontSize: '14px' }}
                        >
                        {post.category}
                        </Link>
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '21px',
                        }}
                    >
                        <Link
                        href={`/blogpage/${post.category}`}
                        underline="hover"
                        sx={{
                            fontWeight: 'bold',
                            color: 'black',
                            fontSize: '21px',
                        }}
                        >
                        {post.title}
                        </Link>
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                        fontSize: '1rem !important',
                        opacity: '.7 !important',
                        mb: '26px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {post.excerpt}
                    </Typography>
                    <Grid2 container spacing={1} alignItems="center">
                        <Grid2 item>
                        <Link href="#" underline="hover">
                            <img
                            src={usericon}
                            alt={post.author}
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                            }}
                            />
                        </Link>
                        </Grid2>
                        <Grid2 item>
                        <Typography variant="body2" color="textSecondary">
                            <Link
                            href={`/blogpage/${post.category}`}
                            underline="hover"
                            color="inherit"
                            sx={{
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            >
                            {post.author}
                            </Link>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {post.date}
                        </Typography>
                        </Grid2>
                    </Grid2>
                    </CardContent>
                </Card>
                </Grid2>
            ))}
            </Grid2>

        </Container>
    </>
  )
}

export default Profile
