import React from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Grid2,
  CardMedia,
  Card,
  TextField,
  Button
} from "@mui/material";
import {
  Facebook,
  Twitter,
  LinkedIn,
  Pinterest,
  Link as LinkIcon,
} from "@mui/icons-material";
import Footer from './Footer';

import image1 from '../Images/HRMS.png';
import image2 from '../Images/CRM.png';
import image3 from '../Images/ERP.png';
import image4 from '../Images/HealthCare.png';
import image5 from '../Images/Assets.svg';
import p1 from '../Images/Usericon.png';

import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';


const BlogPage = () => {
  const { blogID } = useParams();

  const shareLinks = [
    {
      icon: <Facebook />,
      url: "#",
    },
    {
      icon: <Twitter />,
      url: "#",
    },
    {
      icon: <LinkedIn />,
      url: "#",
    },
    {
      icon: <Pinterest />,
      url: "#",
    },
    {
      icon: <LinkIcon />,
      url: "#",
    },
  ];

  const blogData = [
    {
      id: "ERP",
      title: "The transformative power of ERP in business operations",
      firstimage:image1,
      firstheading: "ERPNext, a powerful open-source ERP platform, offers a comprehensive suite of tools designed to address these challenges. This blog post explores the various functionalities of ERPNext, including its capabilities in managing finance, human resources, inventory, and customer relationships. We will delve into key features that facilitate real-time data access, automate routine processes, and provide insightful analytics, ultimately empowering businesses to make informed decisions and drive growth.",
      Secondheading: "The Heart of Texas Business: ERP",
      Thirdheading:"ERP systems are essential for organizations looking to enhance efficiency, improve decision-making, and maintain a competitive advantage. By centralizing data, automating processes, and providing real-time insights, ERP systems empower businesses to operate more effectively in today’s fast-paced environment.",
      Fourtheading:"With robust data analysis capabilities, ERPNext allows organizations to conduct forecasting and scenario planning. Businesses can simulate different operational scenarios based on historical data, enabling them to make proactive adjustments to strategies and operations.",
      Lastheadinng:"The platform streamlines supplier management and purchase order processes, enabling organizations to efficiently handle procurement. Automated purchase orders and supplier evaluations improve supplier relationships and help negotiate better terms."
    },
    {
      id: "CRM",
      title: "The value of CRM in enhancing customer relationships",
       firstimage:image2,
      firstheading: " Customer Relationship Management (CRM) is essential for businesses aiming to build strong relationships with their customers, streamline sales processes, and enhance overall customer satisfaction. Here are several key reasons why implementing a CRM system is crucial for modern organizations.",
      Secondheading: "The Core of Customer Success: CRM",
      Thirdheading:" The CRM module in ERPNext HRMS provides organizations with a powerful tool for managing customer relationships and optimizing sales processes. By integrating lead management, communication tools, sales order handling, and customer insights, ERPNext enables businesses to enhance customer satisfaction and drive revenue growth. Implementing an effective CRM strategy within ERPNext not only improves sales efficiency but also fosters long-term customer loyalty and engagement.",
      Fourtheading:"Generate real-time reports to analyze sales performance, track conversion rates, and identify trends. These insights support data-driven decision-making and strategic planning for sales initiatives.",
      Lastheadinng:"ERPNext maintains detailed profiles for each customer, including interaction history, preferences, and transaction records. This information helps sales teams tailor their approaches and provide personalized service."
    },
    {
      id: "HRMS",
      title: "Enhancing Workforce Management with ERPNext HRMS: A Complete Guide",
      firstimage:image3,
      firstheading: "Effective HRM is crucial for organizations focused on fostering a productive, engaged, and compliant workforce. ERPNext, a powerful open-source ERP platform, offers a comprehensive HRMS  module that streamlines every aspect of HR—from recruitment and onboarding to payroll, attendance, and performance management. ERPNext HRMS empowers HR teams with tools to manage the entire employee lifecycle, ensuring smooth operations, enhanced employee experience, and data-driven decision-making. With ERPNext, businesses can align their HR strategies to support organizational goals, reduce administrative burdens, and focus on nurturing talent and growth.",
      Secondheading: "The Core of Workforce Excellence: HRMS",
      Thirdheading:" ERPNext’s Recruitment Management module transforms the hiring process by offering an integrated, efficient, and user-friendly solution for HR teams. From job postings to candidate selection, each step is designed to improve accuracy, reduce administrative workload, and enhance the overall candidate experience. By leveraging ERPNext, organizations can streamline recruitment efforts, making it easier to attract top talent and make informed hiring decisions that align with their long-term goals.",
      Fourtheading: "Post-interview, ERPNext provides tools for interviewers to rate and provide feedback on candidates, ensuring objective evaluations. These evaluations are stored in the candidate’s profile, allowing for easy comparison and final selection.",
      Lastheadinng:"ERPNext simplifies interview scheduling by enabling interviewers and candidates to coordinate time slots directly within the system, ensuring an organized and professional experience for all parties."
    },
    {
      id: "HealthCare",
      title: "The Role of Technology in Transforming Healthcare Delivery and Patient Care",
      firstimage:image4,
      firstheading: "ERPNext’s healthcare module within the HRMS framework provides a powerful solution for healthcare facilities seeking to improve patient care, streamline operations, and enhance financial management. By integrating features such as patient records, appointment scheduling, billing, inventory tracking, and analytics, ERPNext equips healthcare organizations with the tools needed to deliver efficient, high-quality healthcare services.",
      Secondheading: "The Foundation of Modern Medicine: Healthcare IT",
      Thirdheading:"Generate reports on patient demographics, common treatments, and outcomes, providing valuable insights for quality improvement initiatives. These reports help healthcare providers make data-driven decisions and optimize patient care.",
      Fourtheading:"ERPNext provides reporting capabilities for financial and operational data, helping healthcare facilities monitor revenue, expenses, and patient flow. These insights enable better financial planning and resource allocation.",
      Lastheadinng:"Maintain comprehensive patient profiles, including demographics, contact information, and medical history. These profiles allow healthcare providers to personalize treatment and make more informed decisions."
    },
    {
      id: "Asset Management",
      title: "Optimizing Business Efficiency with Effective Asset Management Strategies",
      firstimage:image5,
      firstheading: "Asset management is essential for any organization aiming to optimize the use of its resources, maintain equipment performance, and manage costs effectively. ERPNext, a leading open-source ERP platform, provides a robust Asset Management module that helps businesses manage the lifecycle of their physical assets—from acquisition to disposal.",
      Secondheading: "Optimizing Resources with Asset Management",
      Thirdheading:"Effective asset management is key to optimizing the use and value of business resources. This blog highlights how ERPNext simplifies asset management, focusing on features for repairs, sales, scrap.",
      Fourtheading:"Set up preventive maintenance schedules for critical assets to reduce the risk of unexpected breakdowns and extend asset lifespan",
      Lastheadinng:"Track every instance of maintenance, detailing the type of repair, associated costs, and the responsible department or team."
    }
  ]

  const blogcontent = blogData.find((ele) => ele.id === blogID)
  
  return (
    <>
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          bgcolor: 'rgb(246 246 247)',
          padding: '20px 20px',
          justifyContent: { xs: 'flex-start', md: 'center' },
          alignItems: { xs: 'flex-start', md: 'center' },
          gap: { xs: 1, md: 2 },
        }}
      >
        <Typography 
            component={Link}
            to = "/"
            sx = {{
                fontWeight:'bold',textAlign:'center',cursor:'pointer',
                color:'black'
            }}
        >
            FusionERP 
        </Typography>
        <Typography
          component={Link}
          to = "/blog"
          sx={{ textAlign: 'center', fontWeight: 'bold' ,textDecoration:'none',color:'black'}}>
            {'>'} Blog 
        </Typography>
        <Typography
          component={Link}
          to = "/blog"
          sx={{ textAlign: 'center', opacity: '.5', fontWeight: 'bold' ,textDecoration:'none',color:'black'}}>
          {'>'} {blogcontent.title } 
        </Typography>
      </Box>
      
    <Box py={6}>
      <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Box textAlign="center" sx={{ width: '100%' }}>
          <Typography
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: { xs: '30px', md: '40px' }, textAlign: 'center' }}
          >
            The Unrealized Potential of{' '}
            <Box
              component="span"
              sx={{
                display: {
                  xs: 'inline',
                  md: 'block',
                },
              }}
            >
              Olfactory Design
            </Box>
          </Typography>

          {/* <Grid2 container justifyContent="center" spacing={1} sx={{ mt: { xs: 4, md: 0 } }}>
            {shareLinks.map((link, index) => (
              <Grid2 item key={index}>
                <IconButton
                  component="a"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    border: '1px solid #12715B',
                    width: 48,
                    height: 48,
                    color: 'black',
                    '&:hover': { backgroundColor: '#12715B', color: 'white' },
                  }}
                >
                  {link.icon}
                </IconButton>
              </Grid2>
            ))}
          </Grid2> */}
        </Box>

        <Card
          sx={{
            my: 4,
            width: { xs: '100%', md: '70%' },
            height: 'auto',
            margin: '0 auto',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
          }}
        >
          <CardMedia
            component="img"
            image={blogcontent.firstimage}
            alt="Olfactory Design"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Card>

        <Box
          sx={{
            px: { xs: 2, md: 15 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mt: 10,
              position: 'relative',
              textIndent: '0',
              lineHeight: '1.5rem',
              letterSpacing: '0.10rem',
              textAlign: { xs: 'justify', md: 'left' },
              '&::first-letter': {
                fontSize: '5rem',
                fontWeight: 'bold',
                lineHeight: '0.9',
                float: 'left',
                marginRight: '10px',
                marginTop: '5px',
              },
            }}
          >
            {blogcontent.firstheading}
          </Typography>

          <Typography variant="h4" gutterBottom sx={{ mt: 5, fontWeight: 'bold', textAlign: 'center' }}>
            {blogcontent.Secondheading}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              position: 'relative',
              textIndent: '0',
              lineHeight: '1.5rem',
              letterSpacing: '0.10rem',
              textAlign: 'justify',
            }}
          >
            {blogcontent.Thirdheading}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              lineHeight: '1.5rem',
              letterSpacing: '0.10rem',
              mt: 5,
              textAlign: 'justify',
            }}
          >
            {blogcontent.Fourtheading}
          </Typography>

          <Typography
            sx={{
              mt: 5,
              lineHeight: '1.5rem',
              letterSpacing: '0.10rem',
              textAlign: 'justify',
            }}
          >
            {blogcontent.Lastheadinng}
          </Typography>
        </Box>
      </Container>
   
        <Box component="hr" sx={{ width: { xs:'40%',md:"80%"}, mt: 5, borderColor: "grey", borderWidth: 0.5, opacity: 0.6 }} />

     
        <Container maxWidth="lg" sx={{ bgcolor: '#F5EEE9', mt: 5 }}>
          <Grid2
            sx={{
              py: 2,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center', // Centers items horizontally
              justifyContent: 'center', // Centers items vertically
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <img
              src={p1}
              alt="per"
              style={{ height: '250px', borderRadius: '12px', marginBottom: '20px' }} // Adds spacing below the image
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                m: 6,
                gap: 4,
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                FusionERP
              </Typography>
              <Typography sx={{ opacity: 0.8, letterSpacing: 0.8 }}>
                Creative and experienced content writer with 6+ years of experience eager to create unique content strategy for FusionERP to turn website visitors into customers.
              </Typography>

              <Typography
                sx={{
                  color: 'grey',
                  display: 'flex',
                  gap: 2,
                  cursor: 'pointer',
                  justifyContent: { xs:'center',md:'left'},
                }}
              >
                <Facebook sx={{ fontSize: 30 }} />
                <Twitter sx={{ fontSize: 30 }} />
                <LinkedIn sx={{ fontSize: 30 }} />
              </Typography>
            </Box>
          </Grid2>
        </Container>


      <Container maxWidth="lg" sx={{ bgcolor: '#F5EEE9', mt: 10,borderRadius:'12px' }}>
        <Grid2 sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '25px',mt:4 }}>Leave a Reply</Typography>
          <Typography sx={{ fontSize: '17px' }}>Your email address will not be published. Required fields are marked *</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
           <TextField
            placeholder="Enter Name"
            defaultValue=""
            size="small"
            fullWidth
            sx={{
              backgroundColor:'white',
              "& .MuiOutlinedInput-root": {
                height: "50px", 
                "& fieldset": {
                 borderColor: "grey", 
                },
                "&:hover fieldset": {
                  borderColor: "#12715B", 
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#12715B",
                },
                
              },
              "& .MuiInputBase-input": {
                color: "black", 

              },
              "& .MuiInputLabel-root": {
                color: "#12715B", 
              },
            }}
          />

           <TextField
            placeholder="Enter Email"
            defaultValue=""
            size="small"
            fullWidth
            sx={{
              backgroundColor:'white',
              "& .MuiOutlinedInput-root": {
                height: "50px", 
                "& fieldset": {
                 borderColor: "grey", 
                },
                "&:hover fieldset": {
                  borderColor: "#12715B", 
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#12715B", // Border color when focused
                },
              },
              "& .MuiInputBase-input": {
                color: "black", // Input text color
              },
              "& .MuiInputLabel-root": {
                color: "#12715B", // Label text color
              },
            }}
          />
          </Box>
          <TextField
             placeholder="Enter Website"
            defaultValue=""
            size="small"
           
            fullWidth
            sx={{
              backgroundColor:'white',
              "& .MuiOutlinedInput-root": {
                height: "50px", 
                "& fieldset": {
                borderColor: "grey", 
                },
                "&:hover fieldset": {
                  borderColor: "#12715B", 
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#12715B", // Border color when focused
                },
              },
              "& .MuiInputBase-input": {
                color: "black", // Input text color
              },
              "& .MuiInputLabel-root": {
                color: "#12715B", // Label text color
              },
            }}
          />
          <TextField
             placeholder="Enter Your comment"
            defaultValue=""
            size="small"
            multiline
            rows={4}
            fullWidth
            sx={{
              backgroundColor:'white',
              "& .MuiOutlinedInput-root": {
              
                "& fieldset": {
                  borderColor: "grey", 
                },
                "&:hover fieldset": {
                  borderColor: "#12715B", 
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#12715B", // Border color when focused
                },
              },
              "& .MuiInputBase-input": {
                color: "black", // Input text color
              },
              "& .MuiInputLabel-root": {
                color: "#12715B", // Label text color
              },
            }}
          />

          <Button
            sx={{
                mb: 7,
                bgcolor: '#12715B',
                color: 'white',
                width: { xs:'auto',md:'15%'},
                textTransform: 'None',
                padding: '12px 22px 12px 12px',
                fontWeight: 'bold',
                fontSize: '14px'
            }}
          >
            Post Comment
          </Button>
        </Grid2>        
      </Container>
    </Box>

      <Box sx={{ mt: 5 }}> <Footer /></Box>
    </>
  );
};

export default BlogPage;
