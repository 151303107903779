import React, { useState} from 'react'
import DarkLightImage from './DarkLightImage'; // Assuming this is your image component
import { Box, Container, Typography,TextField,Grid2,Button, IconButton} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import loc from '../Images/icon-location.svg';
import chat from '../Images/icon-chat.svg';
import report_issue from "../Images/icon-internet.svg";
import iconcommunity from "../Images/icon-community.svg";

import Footer  from './Footer';
import PricingProfile from './PricingProfile';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import {Snackbar} from "@mui/material"
  
function ContactSection() {

  const vistingcard = [
    {image:loc,title:'Visit Us',location:'FusionERP',button:'Visit Us'},
    {image:chat,title:'Via chat',location:'Get instant answers.',button:`Let's chat `},
    {image:report_issue,title:'Report Issue',location:'Get priority support.',button:'Send Report'},
    {image:iconcommunity,title:'Our community',location:'Connect with users.',button:'Join us now'},
  ]

  const questions = [
    { question: "Is there a limit to sending emails?", answer:  "Yes, if you use  email account/ SMTP there is a limit of 500 emails per month.With FusionERP you can easily be configured with any of your email accounts using SMTP and using this you can send Unlimited Emails.There is no limit to emails if you send emails using your own account."},
    { question: "Is there a limit to the storage?", answer: "Yes, there is a storage limit of 25GB per site. The storage limit can be extended with an additional cost of $30 / 25 GB / Month" },
    { question: "Do you have any partnership programs?", answer:  "Yes, we do. To become our partner visit at partner.fusionerp.in" },
    { question: "Will I get access to all future updates and features?", answer: "Of course! If you purchase our deal now, you will get all future updates and features without any additional costs" },
    { question: "What does 'unlimited users' mean?", answer: "You can add any number of users to your plan without paying for additional users." },
  ];

  const[openAnswers,setOpenAnswers] = useState(Array(questions.length).fill(false))

  const toggleAnswer = (index) =>{
    setOpenAnswers((prevOpenAnswers) => 
      prevOpenAnswers.map((open,i) => (i === index ? !open : open))
    )
  }



    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState('');


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    mobileNo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
        cache: 'no-cache',
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      
      if (result.message) {
        if (result.message.message.success === "Email ID added successfully") {
          setSnackbarMessage("Email ID added successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);

        } else if (result.message && result.message.message && result.message.message.error) {
          let errorMessage = result.message.message.error;
          setSnackbarSeverity(errorMessage === 'Email ID already exists' ? 'warning' : 'error');
          setSnackbarTitle(errorMessage === 'Email ID already exists' ? 'Warning' : 'Error');
          setSnackbarMessage(errorMessage === 'Email ID already exists' ? 'You already requested, please check your email.' : errorMessage);
          setSnackbarOpen(true);
        }
      }
      
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarTitle('Error');
      setSnackbarOpen(true);
    }
  };




  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column', 
          mt: { xs:5,md:15},
          position: 'relative',
          width: '100%',
          zIndex: 1
        }}
      >
        {/* Left DarkLightImage */}
        <Box
          sx={{
            position: 'absolute',
            left: 100,
            top: 50,
            width: '20%',
            height: '100%',
          
          }}
        >
          <DarkLightImage />
        </Box>

        {/* Right DarkLightImage */}
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '20%',
            height: '100%',
          
          }}
        >
          <DarkLightImage />
        </Box>

        {/* Center Text */}
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{ fontSize: { xs: '2.5em', md: '4em' }, lineHeight: { xs:'0.9em',md:'1em'}, fontWeight: 'bold' }}>
            Let's get in touch.
          </Typography>
          <Typography 
              sx={{ 
                opacity: '60%', 
                fontSize: '14px', 
                maxWidth: '700px',
                letterSpacing:'0.026em', 
                mt: {xs:2 ,md:4},
              mr: { xs: 2, md: 4 },
                ml:{xs:2,md:0}
            }}
          >
            Feel free to reach out to us using the options below, and our dedicated team will 
            {''}
            <Box component='span' sx = {{display:'inline',md:'block'}}>
               respond to your inquiries promptly.
            </Box>
           
          </Typography>
        </Box>
      </Box>
      <Container
        sx={{
          width: { xs: '95%', md: '100%' },
          maxWidth: '100%',
          minHeight: '550px',
          padding: 0,
          mt: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            backgroundColor: 'gray.800',
            minHeight: '150px',
            margin: 0,
          }}
        >
          {/* First Section - Background Image */}
          <Box
            sx={{
              flex: 1,
              backgroundImage: `url(https://lexend.themegenix.com/four/wp-content/uploads/2024/07/hero-contact.webp), linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: { xs: '12px', md: '0px 12px 12px 0px' },
              minHeight: '550px',
              color: 'white',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: 0,
              margin: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                padding: '8px',
                paddingTop: '200px',
                ml: 10,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  color: 'rgb(255 255 255)',
                  fontSize: '1.25rem',
                }}
              >
                “Software that cover all aspects of your growing business.”
              </Typography>
              {/* <Typography variant="body2" sx={{ mb: 1, fontSize: '1.25rem' }}>
                David Larry
              </Typography>
              <Typography variant="caption" sx={{ fontSize: '1.25rem' }}>
                Founder & CEO
              </Typography> */}
            </Box>
          </Box>

          {/* Second Section - Contact Form */}
          <Box
            sx={{
              bgcolor: '#F5EEE9',
              flex: 1,
              zIndex: 999,
              borderRadius: '12px 0px 0px 12px',
              mt: { xs: 0, md: 0 },
              padding: '50px 20px', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center', 
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                textAlign: 'center',
                opacity: 0.6,
                fontSize: '14px',
              }}
            >
              Have a question or feedback? Fill out the form below, and we'll get back
              to you as soon as possible.
            </Typography>

            <form  onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '500px' }}>
            
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 2, 
                  mb: 2,
                }}
              >
                <TextField
                  color="success"
                  id="outlined-controlled-1"                
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                  required
                />

                <TextField
                  color="success"
                  id="outlined-uncontrolled-2"
                  label="Your email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                  required
                />
              </Box>

              <TextField
                color="success"
                fullWidth
                label="Subject"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  backgroundColor: 'white',
                }}
                required
              />

              <TextField
                color="success"
                fullWidth
                label="Comment"
                placeholder="Your message..."
                id="comment-box"
                multiline
                rows={4}
                name="agenda"
                value={formData.agenda}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  backgroundColor: 'white',
                }}
                required
              />

              <Button
                type="submit"
                fullWidth
                sx={{
                  bgcolor: '#12715B',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  height: '47px',
                  width: '100%',
                }}
              >
                Send Message
              </Button>
            </form>

            {/* Email Contact */}
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="body1">
                Or drop us a message via{' '}
                <span
                  style={{ color: '#12715B', fontWeight: 'bold' }}
                >
                  email
                </span>
                .
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>




      <Box sx={{ mt: 10, mb: 5 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' ,fontSize:{xs:'20px',md:'37px'}}}>
          Other ways to reach us
        </Typography>
      </Box>

      <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: { xs: 'column', md: 'row' },
            ml: { xs: 5, md: 0 },
            gap: 3, 
            transition:'transform 0.3s ease',
            

          }}
        >
        {vistingcard.map((card, index) => (
          <Grid2
            key={index}
            sx={{
              bgcolor: '#f5eee9',
              width: {xs:'90%',md:'295px'}, 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: 2,
              borderRadius: '12px',
              paddingBottom:{xs:'20px',md:'30px'},
              transition:'transform 0.3s ease',
              '&:hover':{
                transform:'translateY(-10px)'
              }
              
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '25px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Typography>
              <img src = {card.image}  alt="images"/>
            </Typography>
            <Typography 
            sx={{ mt: 2, 
              fontWeight: 'bold', 
              textAlign: 'center',
              fontSize:'15px',
              }}
            >
              {card.title}
            </Typography>
            <Typography variant="body2" 
              sx={{ textAlign: 'center', 
                opacity:'70%',
                fontSize:'13px'
              }}>
              {card.location}
            </Typography>
            <Typography
                sx={{
                  mt: 3,
                  fontWeight: 'bold',
                  color: '#12715B',
                  display: 'flex',
                  alignItems: 'center', 
                  fontSize:'14px',
                  cursor:'pointer'
                }}
              >
                {card.button}
                <ArrowForwardIcon fontSize="small" sx={{ ml: 1 }} />
              </Typography>

          </Grid2>
        ))}
      </Box>

      <Box sx={{ textAlign: 'center', justifyContent: 'center' }}>
        <Typography
          sx={{
            mt: 10,
            fontSize: { xs: '2.3em', md: '2.5em' },
            lineHeight: '1.1em',
            letterSpacing: '-0.08rem',
            fontWeight: 'bold',
            mb: { xs: 4, md: 5 },
          }}
        >
          Frequently asked questions:
        </Typography>

              <Container
                maxWidth="sm"
            sx={{
            
              bgcolor: '#F5EEE9',
              padding: { xs: 1, md: 2.5 },
              borderRadius: '15px',
              mb: 10,
          }}
        >
          {questions.map((item, index) => (
            <Box key={index} mb={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  gap: 1,
                }}
              >
                {/* Question Text */}
                <Typography
                  variant="h6"
                  onClick={() => toggleAnswer(index)}
                  sx={{
                    mt: { xs: 1, md: 0 },
                    fontSize: { xs: '12px', md: '18px' },
                    cursor: 'pointer',
                    color: '#333',
                    ml: { xs: 2, md: 3 },
                    fontWeight: 'bold',
                    paddingRight: 0, 
                  }}
                >
                  {item.question}
                </Typography>

                <Box sx={{ mr: 1 }}> 
                  <IconButton onClick={() => toggleAnswer(index)}>
                    {openAnswers[index] ? (
                      <CloseIcon fontSize="small" />
                    ) : (
                      <AddIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </Box>

              {openAnswers[index] && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{
                    ml: { xs: 2, md: 3.5 },
                    textAlign: 'left',
                    mt: 1,
                    wordWrap: 'break-word',
                    maxWidth: 'calc(100% - 100px)',
                  }}
                >
                  {item.answer}
                </Typography>
              )}
            </Box>
          ))}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Container>
      </Box>



      <Box sx  = {{mt:5}}>
        <PricingProfile />
      </Box>

    <Box>
      <Footer />
    </Box>
    </>
  );
}

export default ContactSection;
