import FusionerpCrmScription from '../Components/FusionerpCrmScription';

const FusionERPCRM = () => {
  return (
    <>			 
      <FusionerpCrmScription />
    </>
  )
}

export default FusionERPCRM