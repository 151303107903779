import React, { useState } from 'react';
import { Typography, Button, Container, useMediaQuery, useTheme, Box } from '@mui/material';
import { styled } from '@mui/system';
import DarkLightImage from './DarkLightImage';

import Fusionerpcall from '../Routes/Fusionerpcall';
import Fusionerpchat from '../Routes/Fusionerpchat';
import Fusionerpcrm from '../Routes/Fusionerpcrm';

const PricingSection = styled('section')(({ theme }) => ({
  textAlign: 'center',
  padding: '50px 20px',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 10px',
  },
}));

const ProductOptions = styled('div')(({ theme }) => ({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexDirection: 'row', 
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row', // Keep them side by side even on small screens
    justifyContent: 'space-around',  // Ensure space is used efficiently
  },
}));

const ProductButton = styled(Button)(({ isSelected }) => ({
  fontWeight: 500,
  lineHeight: '1.2',
  fontFamily: 'Poppins, sans-serif',
  color: isSelected ? '#fff' : 'gray',
  margin: '5px',
  borderRadius: '50px',
  backgroundColor: isSelected ? '#12715B' : 'transparent',
  border: isSelected ? '2px solid #12715B' : 'none',
  height: '38px',
  minWidth: '120px',
  '&:hover': {
    backgroundColor: isSelected ? '#12715B' : 'transparent',
    borderColor: isSelected ? '#12715B' : 'transparent',
  },
}));

const Pricing = () => {
  const [selectedProduct, setSelectedProduct] = useState('Fusionerp Call');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  return (
    <Container>
      <PricingSection>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' },  // Stack on mobile
            alignItems: 'center', 
            justifyContent: 'center', 
            width: '100%',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          {/* Left star image */}
          <Box 
            sx={{ 
              mr: { xs: 0, md: 10 }, 
              mb: { xs: 4, md: 0 } 
            }}
          >
            <DarkLightImage />
          </Box>

          <Box 
            sx={{ 
              textAlign: 'center', 
              mt: { xs: 2, md: 5 }, 
              mx: { xs: 2, md: 2 },
              mb:{ xs: 2, md: 4 }
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '2.5em', md: '4em' },
                lineHeight: '1em',
                letterSpacing: { xs: '-0.002rem', md: '-0.16rem' },
                fontWeight: 'bold',
              }}
            >
              Simple, scalable pricing.
            </Typography>
          </Box>

          {/* Right star image */}
          <Box 
            sx={{ 
              ml: { xs: 0, md: 2 }, 
              mt: { xs: 4, md: 0 }
            }}
          >
            <DarkLightImage />
          </Box>
        </Box>

        <Box
          sx={{
            width: {
              xs: '100%', 
              sm: '50%',
              md: '36%', 
              lg: '36%', 
            },
            height: '40px',
            border: '1px solid black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: 1,
            margin: 'auto',
            borderRadius: '10px',
          }}
        >
          <ProductOptions>
            <ProductButton
              isSelected={selectedProduct === 'Fusionerp Call'}
              onClick={() => handleProductClick('Fusionerp Call')}
            >
              HRMS
            </ProductButton>
            <ProductButton
              isSelected={selectedProduct === 'Fusionerp CRM'}
              onClick={() => handleProductClick('Fusionerp CRM')}
            >
              CRM
            </ProductButton>
            <ProductButton
              isSelected={selectedProduct === 'Fusionerpcrm Chat'}
              onClick={() => handleProductClick('Fusionerpcrm Chat')}
            >
              ERP
            </ProductButton>
          </ProductOptions>
        </Box>
      </PricingSection>

      {/* Dynamic Content Rendering */}
      <div>
        {selectedProduct === 'Fusionerp Call' && <Fusionerpcall />}
        {selectedProduct === 'Fusionerp CRM' && <Fusionerpchat />}
        {selectedProduct === 'Fusionerpcrm Chat' && <Fusionerpcrm />}
      </div>
    </Container>
  );
};

export default Pricing;
