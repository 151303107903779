import { Container, Typography, Link, Box, Grid2 } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const socialLinks = [
  { icon: <FacebookIcon />, alt: 'Facebook' },
  { icon: <TwitterIcon />, alt: 'Twitter' },
  { icon: <LinkedInIcon />, alt: 'LinkedIn' },
  { icon: <YouTubeIcon />, alt: 'YouTube' },
];

const Footer = () => {
  return (
    <footer>
      <Container maxWidth='100%' sx={{ padding: 4 ,bgcolor:'black',color:'white'}}>
        <Box display="flex" justifyContent='center' flexWrap="wrap" mb={4}>
          <Box display="flex" gap={2}>
            {socialLinks.map((link, index) => (
              <Link key={index} href={link.href} target="_blank" sx={{ color: 'black' }}>
                {link.icon}
              </Link>
            ))}
          </Box>
        </Box>

        <Box mb={4}>
          <Grid2 container spacing={2} justifyContent="space-evenly" >
            {/* Trust in Power of FusionERP */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, whiteSpace: 'nowrap' }}>
                FusionERP
              </Typography>
            </Grid2>
            {/* Products Section */}
            <Grid2 item direction="column" xs={12} sm={6} md={3} lg={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}  >
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                Products
              </Typography>
              <Link href="/erp" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP ERP</Link>
              <Link href="/crm" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP CRM</Link>
              <Link href="/hrms" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP HRMS</Link>             
              <Link href="/health-care" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP HealthCare</Link>
              <Link href="/assets" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP Asset Management</Link>
            </Grid2>
            {/* Pricing Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                Pricing
              </Typography>
              <Link href="/pricing" sx={{ textDecoration: 'none', fontSize: '14px', color:'white'}}>Plans</Link>
            </Grid2>
            {/* Resource Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                Resource
              </Typography>
              <Link  sx={{ textDecoration: 'none', fontSize: '14px',color:'white' }}>About Us</Link>
             
            </Grid2>
            {/* Get Help Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                Get Help
              </Typography>
              <Link  sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>Chat Support</Link>
              
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
