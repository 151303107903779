
import FusionerpScription from '../Components/FusionerpScription';

const Fusionerpcall = () => {
  return (
    <>			 
      <FusionerpScription />
    </>
  )
}

export default Fusionerpcall