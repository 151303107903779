import { Container, Grid2, Card, CardMedia, CardContent, Typography, Link ,Box} from "@mui/material";
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import image1 from '../Images/erp-blog.jpg';
import image2 from '../Images/blogcrm.avif';
import image3 from '../Images/BLOGHRMS.png';
import usericon from '../Images/Usericon.png';
function PricingProfile() {
    const blogPosts = [
        {
          imgSrc: image1,
          category: "ERP",
          title: "The transformative power of ERP in business operations",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Manjunath Sajjan",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          imgSrc: image2,
          category: "CRM",
          title:  "The value of CRM in enhancing customer relationships",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Chris Thomas",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          imgSrc:image3,
          category: "HRMS",
          title:  "Enhancing Workforce Management with ERPNext HRMS: A Complete Guide",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Sreesandhya Sajja",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
    ];
    
  return (
    <>           
        <Container
            maxWidth={false}
            sx={{
                bgcolor: '#F5EEE9',
                padding: {
                    xs: '20px',
                    md: '50px'
                },
            }}
        >
            <Box sx={{ 
                ml:0,
                alignItems: 'center' }}>
                <Box>
                    <Typography 
                        sx={{
                            fontSize: {xs:'1.81em',md:'3em'},
                            lineHeight: '1.1em',
                            letterSpacing: '-0.12rem',
                            fontWeight: 'bold',
                            textAlign:'center',
                            justifyContent:'center',
                            mb:{xs:3,md:10},
                            mt:{xs:2,md:0},
                        }}
                    >
                        Gain valuable insights
                    </Typography>
                </Box>
            </Box>

            {/* Profile Section */}
            <Grid2 container spacing={10} 
                sx={{ 
                    mt: {xs:1,md:5},
                    ml:-4,
                    flexDirection: {xs:'column',md:'row'}, 
                    flexWrap: 'nowrap', 
                    overflow: 'auto',
                    justifyContent:'center'
                }}
            >
                {blogPosts.map((post, index) => (
                    <Grid2 item key={index}
                        sx={{
                            flexShrink: 0,
                            maxWidth: { xs: 300, md: 350 },
                            height: 450
                        }}
                    >
                        <Card sx={{ 
                            width: '100%', 
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', 
                            borderRadius: 3,
                            textAlign: 'center',
                            padding: 2,
                            height:400
                        }}>
                            <Box sx={{ position: 'relative' }}>
                                {/* Image */}
                                <CardMedia
                                    component="img"
                                    height="220"
                                    image={post.imgSrc}
                                    alt={post.title}
                                    loading="lazy"
                                    sx={{ 
                                        objectFit: "cover", 
                                        borderRadius: 2,
                                        '&:hover': {
                                            transform: 'scale(1.1)', 
                                            transition: 'transform 0.3s ease',
                                        }
                                    }}
                                />

                                {/* Category Label positioned on the top-left of the image */}
                                <Typography 
                                    variant="body2" 
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        left: 8,
                                        backgroundColor: '#12715B', 
                                        color: 'white', 
                                        padding: '4px 12px',
                                        borderRadius: '12px',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {post.category}
                                </Typography>
                            </Box>

                            <CardContent>
                                <Typography variant="h6" component="h2" sx={{ 
                                    fontWeight: '800', 
                                    color: 'black', 
                                    fontSize: '22px', 
                                    mb: 1 
                                }}>

                                    <Link href={`/blogpage/${post.category}`} underline="hover" sx={{ color: 'black' }}>
                                        {post.title}
                                    </Link>
                                </Typography>

                                {/* Author and Date */}
                                <Grid2 container spacing={1} alignItems="center" justifyContent="center">
                                    <Grid2 item>
                                        <Link href={`/blogpage/${post.category}`} underline="hover">
                                            <img
                                                src={usericon}
                                                alt={post.author}
                                                style={{ width: 40, height: 40, borderRadius: "50%" }}
                                            />
                                        </Link>
                                    </Grid2>
                                    <Grid2 item sx = {{display:'flex',gap:2}}>
                                        <Typography variant="body2" color="textSecondary">
                                            <Link href={`/blogpage/${post.category}`} underline="hover" sx={{ fontWeight: 'bold', color: 'black' }}>
                                                {post.author}
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {post.date}
                                        </Typography>
                                    </Grid2>
                                </Grid2>                               
                            </CardContent>                           
                        </Card>
                    </Grid2>
                ))}
            </Grid2>
            <Box sx={{ textAlign: 'center', m: 5 }}>
                <Typography href = "/blog" sx={{ mt: {xs:3,md:12}, display: 'flex', alignItems: 'center', justifyContent: 'center',fontWeight:'bold',cursor:'pointer',color:'#12715B' }}>
                    View more articles <ArrowForwardIcon sx={{ ml: 1 }} />
                </Typography>
            </Box>
        </Container>
    </>
  )
}

export default PricingProfile