import { Box, Typography,TextField,Button } from '@mui/material'
import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Footer  from './Footer';


function Login() {
    return (
        <>
            <Box sx = {{
                display:'flex',
                bgcolor:'rgb(246 246 247)',
                padding:'20px 20px',
                justifyContent: 'center',
                gap: { xs:2,md:1}

            }}>
                <Typography 
                    component={Link}
                    to = "/"
                    sx = {{
                        fontWeight:'bold',textAlign:'center',cursor:'pointer',
                        color:'black'
                    }}
                >
                    FusionERP 
                </Typography>
                <Typography sx = {{textAlign:'center',opacity:'.5',fontWeight:500}}>
                    {'>'} My    account 
                </Typography>
            </Box>

<Box 
    sx={{ 
        display: 'flex',  // Enable flexbox
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',  // Center vertically
        height: '100vh',  // Take full viewport height
        p: { xs: 1, md: 12 },  // Padding for spacing
        mt: { xs: 5, md: 0 },
    }}
>
    <Box
        sx={{
            borderRadius: '10px',
            padding: "20px 20px",
            bgcolor: "#F5EEE9",
            maxWidth: "568px",
            mt: 3,
            mb: 10
        }}
    >
        <Typography
            sx={{
                fontWeight: "bold",
                fontSize: "2.5rem",
                lineHeight: 1.1,
            }}
        >
            Login
        </Typography>
        <Box sx={{ mt: 5 }}>
            <TextField
                required
                color="success"
                fullWidth
                label="Email"
                id="email"
                sx={{ width: "96%", mb: 4, backgroundColor: 'white' }}
            />
            <TextField
                required
                color="success"
                fullWidth
                label="Password"
                id="password"
                sx={{ width: "96%", backgroundColor: 'white' }}
            />
        </Box>
        <Box
            sx={{
                flexDirection: { xs: 'column', md: 'row' },
                display: "flex",
                justifyContent: "space-between",
                alignItems: { xs: 'left', md: "center" },
                mt: 3,
                gap: 2,
                ml: 1.2
            }}
        >
            <FormControlLabel
                control={<Checkbox color="success" />}
                label="Remember me"
            />
            <Typography
                sx={{
                    cursor: "pointer",
                    color: "#12715B",
                    textDecoration: "underline",
                }}
            >
                Lost your password?
            </Typography>
        </Box>
        <Button 
            disabled={true}
            sx={{
                fontWeight: 'bold',
                fontSize: '15px',
                mt: 2,
                mb: 4,
                ml: 1,
                color: 'white',
                bgcolor: '#12715B',
                padding: '10px 30px',
                textTransform: 'none',
                borderRadius: '10px',
                width: 'auto',
            }}
        >
            Log in
        </Button>
        <Typography sx={{ textAlign: 'center', mb: 5, mt: { xs: 2, md: 0 } }}>
            Don't have an account yet?
            <Link
                to="/signup"
                style={{
                    color: "#12715B",
                    fontWeight: "bold",
                    textDecoration: "none",
                }}
            >
                Sign Up
            </Link>
        </Typography>
    </Box>
</Box>

            <Footer />
        </>
    )
}

export default Login
