import React from 'react'
import { Box,Typography,Stack, Container, Grid2,Card,CardContent} from '@mui/material';
import DarkLightImage from './DarkLightImage';

import h1 from '../Images/hero-about-01.webp';
import h2 from '../Images/hero-about-02.webp';

import diamond from "../Images/icon-diamond.svg";
import trophy from "../Images/icon-trophy.svg";
import global from "../Images/icon-globe.svg";
import crown from "../Images/icon-crown.svg";

import think from "../Images/thinking.png";
import msg from "../Images/send-message.png";

import girl from "../Images/hand-pencil.png";
import drawing from "../Images/empathing.png";

import Footer from "./Footer";
function About() {
    const blogPosts = [
        {
          id:"ERP",
          imgSrc: diamond,
          category: "ERPS",
          title: "Make an impact",
          author: "We’re building something big. Something that has the power to change the trajectory of any sized business for the better experience.",
        },
        {
          id:"CRM",
          imgSrc: trophy,
          category: "CRM",
          title: "Learn",
          author: "FusionERP team are masters of their craft. Even though we’re all experts in our respective fields, we always make time to expand our minds.",
        },
        {
          id:"HRMS",
          imgSrc: global,
          category: "Have fun",
          title: "Have fun",
          author: "We work hard and play harder. We believe in the importance of celebrating wins big or small, for the business or individuals.",
         
        },
        {
          id:"HealthCare",
          imgSrc: crown,
          category: "Empathy",
          title: "Empathy",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "We strive to be empathetic to every customer and colleague and by doing so we can provide a better experience for all.",
         
        },

      ];

    return (
        <>

            <Box 
                sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                flexDirection: 'column', 
                mt: { xs:5,md:10},
                position: 'relative',
                width: '100%',
                zIndex: 1
                }}
            >
                {/* Left DarkLightImage */}
                <Box
                sx={{
                    position: 'absolute',
                    left: 100,
                    top: 50,
                    width: '20%',
                    height: '100%',
                
                }}
                >
                <DarkLightImage />
                </Box>

                {/* Right DarkLightImage */}
                <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: '20%',
                    height: '100%',
                
                }}
                >
                <DarkLightImage />
                </Box>

                {/* Center Text */}
                <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='h2'
                                sx={{
                                    fontSize: { xs: '2.5em', md: '4em' }, lineHeight: { xs: '0.9em', md: '1em' }, fontWeight: 'bold',
                                    letterSpacing:'-0.16rem'
                                }}>
                    About FusionERP.
                </Typography>
                <Typography 
                    sx={{ 
                        opacity: '60%', 
                        fontSize: '15px', 
                        maxWidth: '700px',
                        letterSpacing:'0.096em', 
                        mt: {xs:2 ,md:4},
                        mr: { xs: 2, md: 4 },
                        ml: { xs: 2, md: 0 },
                    }}
                >
                    In 2014, Steven Smith have gotten so much of our time back that we're now able to put towards things
                    {''}
                    <Box component='span' sx = {{display:'inline',md:'block'}}>
                    that are actually helping our company as opposed to just throwing content out there. and the idea of
                                </Box>
                                        <Box component='span' sx = {{display:'inline',md:'block'}}>
                                FusionERP was born. Today, FusionERP empowers teams to easily communicate with customers through
                                </Box>
                                personalized documents that can be created in minutes, build meaningful relationships.
                
                </Typography>
                </Box>
            </Box>
            <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{
                margin: '0 auto',
                mt: 4,
                maxWidth: '1200px',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            >
            <Box
                sx={{
                position: 'relative',
                display: 'inline-block',
                overflow: 'hidden', // Ensures the image doesn't overflow on small screens
                width: '100%', // Allows the Box to adapt to screen width
                maxWidth: { xs: '100%', md: '50%' }, // Adapts based on screen size
                }}
            >
                <img
                src={h1}
                alt="h1"
                style={{
                    width: '500px',
                    height: '500px',
                    objectFit: 'cover',
                    borderRadius: '12px',
                }}
                />
                <img
                src={girl}
                alt="girl"
                style={{
                    position: 'absolute',
                    top: '-60px',
                    left: '-60px',
                    width: '250px',
                    height: '450px',
                    objectFit: 'contain',
                    borderRadius: '12px',
                }}
                />
            </Box>

            <Box
                sx={{
                position: 'relative',
                display: 'inline-block',
                overflow: 'hidden', // Ensures the image doesn't overflow on small screens
                width: '100%', // Allows the Box to adapt to screen width
                maxWidth: { xs: '100%', md: '50%' }, // Adapts based on screen size
                }}
            >
                <img
                src={h2}
                alt="h2"
                style={{
                    width: '750px',
                    height: '500px',
                    objectFit: 'cover',
                    borderRadius: '12px',
                }}
                />
                <img
                src={drawing}
                alt="drawing"
                style={{
                    position: 'absolute',
                    top: '200px',
                    right: '1px',
                    width: '250px',
                    height: '450px',
                    objectFit: 'contain',
                    borderRadius: '12px',
                }}
                />
            </Box>
            </Stack>


            <Container maxWidth={false} sx={{ bgcolor: '#F5EEE9', mt: 5, p: { xs: 4, md: 8 } }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1.8rem', sm: '2.5rem' },
                    p: 4,
                }}
                >
                How FusionERP Helps
                </Typography>

                <Typography
                sx={{
                    width: { xs: '100%', sm: '77%' },
                    textAlign: 'center',
                    margin: '0 auto',
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                }}
                >
                Teams use FusionERP to improve document workflows, insights, and speed while delivering an amazing experience for their customers. Businesses trust FusionERP all-in-one document automation software to streamline the process to create, approve, and eSign proposals, quotes, contracts, and more. With powerful document creation and workflow capabilities, teams can provide their customers with a more professional, timely, and engaging experience.
                </Typography>

                <Typography
                sx={{
                    width: { xs: '100%', sm: '77%' },
                    textAlign: 'center',
                    margin: '0 auto',
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                    mt: 3,
                }}
                >
                In 2014, Steven Smith have gotten so much of our time back that we're now able to put towards things that are actually helping our company as opposed to just throwing content out there. And the idea of FusionERP was born. Today, FusionERP empowers teams to easily communicate with customers through personalized documents that can be created in minutes, build meaningful relationships...
                </Typography>
            </Box>
            </Container>

            
            <Container sx = {{mt:2}}>
                <Box
                    sx={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        mt: 10,
                        fontWeight: 'bold',
                        fontSize:'30px'
                    }}
                >
                    Our Values it’s Simple!
                </Box>
                <Grid2
                    container
                    spacing={10}
                    sx={{
                        mt: 7,
                        ml: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                {blogPosts.map((post, index) => (
                    <Grid2
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            maxWidth: 420,
                            flexShrink: 0,
                        }}
                    >
                    <Card
                        sx={{
                            width: { xs: '100%', sm: '300px', md: '450px' }, 
                            height: { xs: 'auto', sm: '350px', md: '250px' }, 
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: 3,
                            textAlign: 'left',
                            padding: 1,
                            bgcolor: '#F5EEE9',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                        }}
                    >
                        <Box 
                            sx={{
                                position: 'relative',
                                width: '20%',
                                height: 200,
                                objectFit: 'cover', 
                                borderRadius: 2,
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                cursor: 'pointer'
                            }}
                        >
                            <img src={post.imgSrc} alt={post.title} />
                        </Box>

                        <CardContent>
                        <Typography
                            variant="h6"
                            sx={{
                            fontWeight: '800',
                            color: 'black',
                            fontSize: '22px',
                                mb: 1,
                                textDecoration: 'none',
                            }}
                        >
                            {post.title}
                        </Typography>

                        {/* Author and Date */}
                        <Grid2
                            container
                            spacing={1}
                            alignItems="left"
                            justifyContent="left"
                        >
                            <Grid2 item>

                            </Grid2>
                            <Grid2 item sx={{ display: 'flex', gap: 2 }}>
                                <Typography variant="body2" color="textSecondary" sx = {{fontSize:'1.1rem'}}>
                                    {post.author}                               
                                </Typography>
                            </Grid2>
                        </Grid2>
                        </CardContent>
                    </Card>
                    </Grid2>
                ))}
                </Grid2>
            </Container>
            {/* Last Index */}
            <Box
                
                sx={{
                    mt:10,
                    minHeight: '70vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#F5EEE9',
                    borderRadius: '16px',
                }}
                >
                <Box
                    sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    }}
                >
                    {/* Left Image */}
                                <Box
                                        sx={{
                                            flex: 1,
                                            height: '100%',
                                           display: { xs: 'none', sm: 'block' },
                              justifyContent: 'flex-start',
                                            
                                        }}
                                    >
                                    <img
                                        src={think}
                                        alt="talking"
                                        style={{
                                            height: '100%',
                                            objectFit: 'cover',
                                            width: '80%',
                                            clipPath: 'inset(0% 0% 0% 0%)',
                                            marginLeft: '-30px',
                                        }}
                                    />
                                        
                                        
                                    </Box>
                                    
                                    <Box>
                                        <DarkLightImage />
                                    </Box>

                                        {/* Center Content */}
                                        <Box
                                        sx={{
                                            flex: 2,
                                            textAlign: 'center',
                                            px: { xs: 2, md: 20 },
                                            py: { xs: 2, md: 4 },
                                        }}
                                        >
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                fontSize: { xs: '1.5rem', md: '3rem' },
                                                lineHeight: '1em',
                                                letterSpacing: '-0.07rem',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            We’re looking for people who share our vision!
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: '1rem', md: '1.25rem' },
                                                opacity: '70%',
                                                mt: 3,
                                            }}
                                        >
                                            Have what it takes to be one of us.
                        </Typography>


                                        
                                        </Box>

                                    <Box>
                                        <DarkLightImage />
                                    </Box>
                                    <Box
                                    sx={{
                                        flex: 1,
                                        height: '100%',
                                        display: { xs: 'none', sm: 'block' },
                              justifyContent: 'flex-end',
                                        mr:'-80px'
                                        
                                        
                                    }}
                                    >
                
                                    <img
                                        src={msg}
                                        alt="chatting"
                                        style={{      
                                            height: '100%',
                                            objectFit: 'cover',
                                            width: '80%',
                                            marginRight:'-50px'
                                        }}
                                    />
                                    </Box>
                        </Box>
                        
            </Box>
            <Box>
                <Footer />
            </Box>

            {/* Last Index  */}

        </>
    )
}

export default About
