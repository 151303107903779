import FusionerpcallScript from '../Components/FusionerpcallScript';


const Fusionerpcall = () => {
  return (
    <>			 
      <FusionerpcallScript />
    </>
  )
}

export default Fusionerpcall