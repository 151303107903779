import React,{useEffect, useState} from 'react';
import Navbar from "./Components/Navbar";
import LandingPage from "./Components/LandingPage";
import Pricing from "./Components/Pricing";
import { BrowserRouter as Router, Routes, Route,useLocation} from 'react-router-dom';
import ContactSection from './Components/ContactSection';
import Login from './Components/Login';
import HRMS from './Components/HRMS';
import data from './Data/data'
import Fab from '@mui/material/Fab';
import { Stack } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import Signup from './Components/Signup';
import Blog from './Components/Blog';
import BlogPage from './Components/BlogPage';
import About from './Components/About';
import SuccessPage from './Components/SuccessPage';

function ScrollTop(){
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  },[pathname])
  return null;
}



function App() {
  
  const [hideuparrow, sethideuparrow] = useState(false);

  const go_to_top = () => {
    window.scrollTo({top:0,behavior:'smooth'})
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        sethideuparrow(true)
      } else {
        sethideuparrow(false)
      }
    })
  }, [])
  

  return (
    <Router>
      <ScrollTop />
      <Navbar />
      <Routes>
        <Route path ="/" element = { <LandingPage />} />
        <Route path="/pricing" element={<Pricing />} />  
        <Route path="/contactus" element={<ContactSection />} />  
        <Route path="/login" element = {<Login /> } />    
        <Route path="/hrms" element = {<HRMS data = {data.HRMS} />} />
        <Route path="/crm" element = {<HRMS data = {data.CRM} />} />
        <Route path="/erp" element={<HRMS data={data.ERP} />} />
        <Route path="/assets" element={<HRMS data={data.Assets} />} />
        <Route path="/health-care" element={<HRMS data={data.HEALTHCARE} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogpage/:blogID" element={<BlogPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/success" element={<SuccessPage />} /> 
      </Routes>
      
      <Stack
        direction="column"
        sx={{
          gap: 2,
          position: 'fixed',
          bottom: 16,
          right: 16
        }}
      >


        {hideuparrow ?
          <Fab size="small"
            sx={{
              bgcolor: '#12715B', color: 'white',
              '&:hover': {
                bgcolor: '#12715B',
                color:'white'
              }
            }}
            onClick={go_to_top}
          >
            <ExpandLessOutlinedIcon /> 
          </Fab>
          : null
        }
      </Stack>
    </Router>
  );
}

export default App;
