import React, { useState } from 'react'
import Container from '@mui/material/Container';
import { Box, Stack, Typography, TextField, Button, InputAdornment, IconButton, Grid2 } from '@mui/material';
import homeimage from "../Images/Fusionerplogo.jpeg";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import image1 from '../Images/HRMS.png';
import image2 from '../Images/CRM.png';
import image3 from '../Images/ERP.png';
import image4 from '../Images/HealthCare.png';
import image5 from '../Images/Assets.svg';
import Footer from './Footer';
import Profile from './Profile';
import talking from "../Images/talking.svg";
import chatting from "../Images/chatting.svg";
import DarkLightImage from './DarkLightImage';
import { Snackbar } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

function LandingPage() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState('');


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        mobileNo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
                cache: 'no-cache',
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.message) {
                if (result.message.message.success === "Email ID added successfully") {
                    setSnackbarMessage("Email ID added successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);

                } else if (result.message && result.message.message && result.message.message.error) {
                    let errorMessage = result.message.message.error;
                    setSnackbarSeverity(errorMessage === 'Email ID already exists' ? 'warning' : 'error');
                    setSnackbarTitle(errorMessage === 'Email ID already exists' ? 'Warning' : 'Error');
                    setSnackbarMessage(errorMessage === 'Email ID already exists' ? 'You already requested, please check your email.' : errorMessage);
                    setSnackbarOpen(true);
                }
            }

        } catch (error) {
            console.error('Error submitting form:', error);
            setSnackbarMessage('An error occurred. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarTitle('Error');
            setSnackbarOpen(true);
        }
    };
    return (
        <>
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    height: { xs: '110vh', md: '100vh' },
                    pt: 5,
                }}
            >

                <Stack
                    direction={{
                        xs: "column",
                        md: "row"
                    }}
                    spacing={0}
                    sx={{
                        justifyContent: 'space-between',
                        height: '100%',
                    }}
                >
                    <Grid2
                        sx={{
                            flex: 1,
                            pt: { xs: 0, md: 7 },
                            ml: { xs: 0, md: 7 },
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '2.6rem',
                                    md: '4rem',
                                    lg: '4rem',
                                    xl: '4rem'
                                },
                                lineHeight: '1em',
                                letterSpacing: '-0.16rem',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                mb: 5,
                            }}
                        >
                            Software that cover <br />
                            <span style={{ color: 'rgb(18 113 91)', fontWeight: 'bold' }}>all aspects</span> of your
                            <br />growing business.
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: { xs: 'center', md: 'left' },
                                fontSize: { xs: '13px', md: '1.300rem' },
                                opacity: 0.6,
                                mb: 4,
                            }}
                        >
                            Say goodbye to the hassle of managing multiple software <br />
                            and tools for different tasks. FusionERP offers a comprehensive <br />
                            suite of tools that cover all aspects of your business.
                        </Typography>


                        {/* Search box */}
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            spacing={2}
                        >
                            <Box>
                                <form onSubmit={handleSubmit} noValidate>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <TextField
                                            color="success"
                                            variant="outlined"
                                            placeholder="Your email address"
                                            size="small"
                                            value={formData.email}
                                            onChange={handleChange}
                                            name="email"
                                            sx={{
                                                width: 350,
                                                borderRadius: 2,
                                                height: 46,
                                                bgcolor: 'white',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                    height: '100%',
                                                    padding: 0,
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                    '&.Mui-focused': {
                                                        backgroundColor: 'transparent',
                                                        borderColor: '#12715B',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    height: '100%',
                                                    padding: '12px',
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" sx={{ ml: 2 }}>
                                                        <MailOutlineIcon sx={{ color: '#12715B' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            href="/signup"
                                            sx={{
                                                bgcolor: '#12715B',
                                                color: 'white',
                                                padding: '11px 17px',
                                                borderRadius: '8px',
                                                fontSize: '15px',
                                                textTransform: 'none',
                                                fontWeight: 'bold',
                                                height: 46,
                                            }}
                                        >
                                            Try it out for free
                                        </Button>
                                    </Stack>
                                </form>

                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleCloseSnackbar}
                                    message={snackbarMessage}
                                    action={
                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            color="inherit"
                                            onClick={handleCloseSnackbar}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    }
                                />
                            </Box>
                        </Stack>




                        <Typography sx={{
                            mt: { xs: 2, md: 3 }, ml: { xs: 0, md: 1 }, fontSize: { xs: '0.875rem', md: '16px' }, opacity: '60%', mb: { xs: 3, md: 'auto' }
                        }}>
                            We care about your data in our
                            <span
                                style={{
                                    color: '#12715B',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    paddingLeft: '5px'
                                }}
                            >
                                privacy policy

                            </span>
                        </Typography>
                    </Grid2>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex', // Flex layout for outer box
                            flexDirection: { xs: 'column', md: 'row' }, // Adjust layout for responsiveness
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 5,
                            order: { xs: 1, md: 2 },
                            padding: { xs: 2, md: 4 },
                            borderRadius: '12px',
                            mr: { xs: 2, md: 4 },
                            maxWidth: { xs: '90%', md: '40%' },
                            mx: 'auto',
                            height: { xs: '300px', md: '330px' },

                        }}
                    >
                        <Box
                            component="img"
                            src={homeimage}
                            alt="secondimage"
                            sx={{
                                width: '100%',
                                height: '110%',
                                objectFit: 'contain',
                                borderRadius: '12px',
                                //   backgroundColor: '#F5EEE9',
                            }}
                        />
                    </Box>

                </Stack>

                {/* <Box sx = {{ textAlign:'center'}}>
              <Typography 
                sx = {{ 
                    fontSize:'2rem',
                    lineHeight:'1.1em',
                    letterSpacing:'-0.07rem',
                    fontWeight:'bold',
                    mb:2
                }}
            >
                Everything you need is right at your fingertips
              </Typography>
              <Typography 
                sx={{
                    fontSize:'1.125rem',
                    opacity:'70% !important'
                    }}
                >
                FusionERP is designed to make your work life easier and more efficient.
              </Typography>
            </Box>  */}

                {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pt:9
                }}
            >
                <Box
                    sx={{
                        width: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: '#F5EEE9',
                        borderRadius: '10px',
                        mb: '5%',
                        mx: 'auto', 
                    }}
                >
                    <Stack
                        direction={{ xs: 'column', md: 'row' }} 
                        spacing={{ xs: 6, md: 0 }} 
                        sx={{
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '100%',
                            pt: 8,
                            pb: 10,
                        }}
                    >
                        <Box textAlign="center">
                            <Typography
                                variant="h2"
                                sx={{
                                    color: '#12715B',
                                    fontSize: { xs: '3rem', md: '4rem' },
                                    lineHeight: '1em',
                                    letterSpacing: '-0.16rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                280+
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Track of leads
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <Typography
                                variant="h2"
                                sx={{
                                    color: '#12715B',
                                    fontSize: { xs: '3rem', md: '4rem' },
                                    lineHeight: '1em',
                                    letterSpacing: '-0.16rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                90%
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Satisfied users
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <Typography
                                variant="h2"
                                sx={{
                                    color: '#12715B',
                                    fontSize: { xs: '3rem', md: '4rem' },
                                    lineHeight: '1em',
                                    letterSpacing: '-0.16rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                10.2k
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Team collaborations
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </Box> */}

                <Box
                    sx=
                    {{
                        textAlign: 'center',
                        justifyContent: 'center',
                        maxwidth: '100%'
                    }}
                >
                    {/* <Typography 
                    sx = {{
                        color:'#12715B',
                        fontWeight:'bold'
                    }}
                >
                    Main features
                </Typography> */}
                    <Typography variant='h2'
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '2.2rem', md: '2.8rem' },
                            lineHeight: '1.1em',
                            letterSpacing: '-0.12rem',
                            mt: 2
                        }}
                    >
                        A Software tools help you keep {' '}
                        <Box
                            component="span"
                            sx={{
                                display: { xs: 'inline', md: 'block' },
                            }}
                        >
                            track of leads
                        </Box>
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: '1rem', md: '14px' },
                            mt: 2,
                            opacity: 0.6,
                            p: { xs: 2, md: 'auto' },
                            textAlign: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        Join the thousands of satisfied users and take your business to the next level.{' '}

                    </Typography>
                </Box>


                <Grid2 size={{ xs: 4, md: 8 }}>

                    {/* Third Image */}
                    <Box display={{ xs: 'column', md: "flex" }}
                        alignItems="center"
                        justifyContent={{
                            xs: "center",
                            md: "space-between"
                        }}
                        sx={{
                            pt: 10,
                            gap: 10
                        }}
                    >
                        <Box
                            flex="1"
                            display={{ xs: 'column', md: 'flex' }}
                            justifyContent="center"
                            sx={{
                                backgroundColor: '#F5EEE9',
                                padding: { xs: 2, md: 4 },
                                borderRadius: '12px',
                                ml: { xs: 2, md: 4 },
                                maxWidth: { xs: '90%', md: '33%' }, 
                                mx: 'auto',
                                height: { xs: '300px', md: '300px' },
                                overflow:'hidden'
                            }}
                        >
                            <Box
                                component="img"
                                src={image3}
                                alt="firstimage"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        transform: 'scale(1.1)', 
                                        transition: 'transform 0.3s ease',
                                    }
                                }}
                            />
                        </Box>
                        <Stack flex="1">
                            <Button
                                sx={{
                                    borderRadius: '12px',
                                    bgcolor: '#12715B',
                                    maxWidth: '50px',
                                    minWidth: '40px',
                                    height: '50px',
                                    padding: 0,
                                    ml: { xs: 2, md: 0 },
                                    mt: { xs: 2, md: 0 }

                                }}
                            >
                                <CloudDownloadIcon
                                    style={{
                                        color: 'whitesmoke',
                                        fontSize: '24px'
                                    }}
                                />
                            </Button>

                            <Box sx={{
                                ml: { xs: 2, md: 0 },
                            }}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '23px', md: '2.5rem' }, lineHeight: '1.1em', letterSpacing: '-0.08rem', fontWeight: 'bold', mt: 5 }}>
                                    Optimize your entire business operations with - Fusion<span style={{ color: '#12715B' }}>ERP</span>
                                </Typography>
                                <Typography variant="body1"
                                    sx={{
                                        mt: 2,
                                        fontSize: { xs: '16px', md: '1.125rem' },
                                        opacity: '70% !important',
                                        textWrap: 'wrap'
                                    }}
                                >
                                    Get your business up and running in seconds with an efficient ERP system.{''}
                                    <Box component="span" sx={{ display: { xs: 'inline', md: 'block' } }}>
                                        FusionERP assists businesses by efficiently managing and organizing their everyday operations through HR, accounting,automation and more.
                                    </Box>
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button sx={{ textTransform: 'none', color: '#12715B', fontWeight: '700 !important' }}
                                        href="/erp"
                                    >
                                        Read More <ArrowForwardIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    {/* Third Image Ends */}


                    {/* Second Image */}
                    <Box
                        display={{ xs: 'flex', md: 'flex' }}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            pt: 10,
                            gap: 10
                        }}
                    >
                        <Stack
                            flex="1"
                            sx={{
                                order: { xs: 2, md: 1 }
                            }}
                        >
                            <Button
                                sx={{
                                    borderRadius: '12px',
                                    bgcolor: '#12715B',
                                    maxWidth: '50px',
                                    minWidth: '40px',
                                    height: '50px',
                                    padding: 0,
                                    ml: { xs: 2, md: 8 },
                                    mt: { xs: 2, md: 0 }
                                }}
                            >
                                <TollOutlinedIcon
                                    style={{
                                        color: 'whitesmoke',
                                        fontSize: '24px'
                                    }}
                                />
                            </Button>

                            <Box sx={{ ml: { xs: 2, md: 8 } }}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '23px', md: '2.5rem' }, lineHeight: '1.1em', letterSpacing: '-0.08rem', fontWeight: 'bold', mt: 5 }}>
                                    Boost your sales & grow your revenue - FusionERP <span style={{ color: '#12715B' }}>CRM</span>
                                </Typography>
                                <Typography variant="body1"
                                    sx={{
                                        mt: 2,
                                        fontSize: { xs: '16px', md: '1.125rem' },
                                        opacity: '70% !important',
                                        textWrap: 'wrap'
                                    }}
                                >
                                    Time is a crucial element in our lives, helping us to structure  {''}
                                    <Box component="span" sx={{ display: { xs: 'inline', md: 'block' } }}>
                                        and organize our daily activities into good habits...
                                    </Box>
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button sx={{ textTransform: 'none', color: '#12715B', fontWeight: '700 !important' }}
                                        href="/crm"
                                    >
                                        Read More <ArrowForwardIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>

                        <Box
                            flex="1"
                            display={{ xs: 'column', md: 'flex' }}
                            justifyContent="center"
                            sx={{
                                order: { xs: 1, md: 2 },
                                backgroundColor: '#F5EEE9',
                                padding: { xs: 2, md: 4 },
                                borderRadius: '12px',
                                mr: { xs: 2, md: 4 },
                                maxWidth: { xs: '90%', md: '33%' },  // Equal width for all boxes
                                mx: 'auto',
                                height: { xs: '300px', md: '300px' },  // Same height for all boxes
                            }}
                        >
                            <Box
                                component="img"
                                src={image2}
                                alt="secondimage"
                                sx={{
                                    width: '100%',  // Ensure image fills the box width
                                    height: '100%',  // Ensure image fills the box height
                                    objectFit: 'cover',  // Maintain aspect ratio and fill the box
                                    borderRadius: '12px',
                                    '&:hover': {
                                        transform: 'scale(1.1)', 
                                        transition: 'transform 0.3s ease',
                                    }
                                
                                }}
                            />
                        </Box>

                    </Box>
                    {/* Second Image ends */}

                    {/* First Image */}
                    <Box display={{ xs: 'column', md: "flex" }}
                        alignItems="center"
                        justifyContent={{
                            xs: "center",
                            md: "space-between"
                        }}
                        sx={{
                            pt: 10,
                            gap: 10
                        }}
                    >
                        <Box
                            flex="1"
                            display={{ xs: 'column', md: 'flex' }}
                            justifyContent="center"
                            sx={{
                                backgroundColor: '#F5EEE9',
                                padding: { xs: 2, md: 4 },
                                borderRadius: '12px',
                                ml: { xs: 2, md: 4 },
                                maxWidth: { xs: '90%', md: '60%' },
                                mx: 'auto',
                                 
                            }}
                        >
                            <Box
                                component="img"
                                src={image1}
                                alt="firstimage"
                                sx={{
                                    maxWidth: { xs: '90%', md: '100%' },
                                    height: 'auto',
                                    borderRadius: '12px',
                                    ml: { xs: 2, md: 0 },
                                    '&:hover': {
                                        transform: 'scale(1.1)', 
                                        transition: 'transform 0.3s ease',
                                    }

                                }}
                            />
                        </Box>




                        <Stack flex="1">
                            <Button
                                sx={{
                                    borderRadius: '12px',
                                    bgcolor: '#12715B',
                                    maxWidth: '50px',
                                    minWidth: '40px',
                                    height: '50px',
                                    padding: 0,
                                    ml: { xs: 2, md: 0 },
                                    mt: { xs: 2, md: 0 }

                                }}
                            >
                                <CloudDownloadIcon
                                    style={{
                                        color: 'whitesmoke',
                                        fontSize: '24px'
                                    }}
                                />
                            </Button>

                            <Box sx={{
                                ml: { xs: 2, md: 0 },
                            }}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '23px', md: '2.5rem' }, lineHeight: '1.1em', letterSpacing: '-0.08rem', fontWeight: 'bold', mt: 5 }}>
                                    Optimize your everyday HR functions with FusionERP <span style={{ color: '#12715B' }}>HRMS</span>
                                </Typography>
                                <Typography variant="body1"
                                    sx={{
                                        mt: 2,
                                        fontSize: { xs: '16px', md: '1.125rem' },
                                        opacity: '70% !important',
                                        textWrap: 'wrap'
                                    }}>
                                    Fusion HRMS offers a complete solution for managing human resources, {''}
                                    <Box component="span" sx={{ display: { xs: 'inline', md: 'block' } }}>
                                        including payroll, attendance, and employee information, all in one place.
                                    </Box>
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button sx={{ textTransform: 'none', color: '#12715B', fontWeight: '700 !important' }} href="/hrms">
                                        Read More <ArrowForwardIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    {/* First Image Ends */}




                    {/* Fourth Image */}
                    <Box
                        display={{ xs: 'flex', md: 'flex' }}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            pt: 10,
                            gap: 10
                        }}
                    >
                        <Stack
                            flex="1"
                            sx={{
                                order: { xs: 2, md: 1 }
                            }}
                        >
                            <Button
                                sx={{
                                    borderRadius: '12px',
                                    bgcolor: '#12715B',
                                    maxWidth: '50px',
                                    minWidth: '40px',
                                    height: '50px',
                                    padding: 0,
                                    ml: { xs: 2, md: 8 },
                                    mt: { xs: 2, md: 0 }
                                }}
                            >
                                <DiamondOutlinedIcon
                                    style={{
                                        color: 'whitesmoke',
                                        fontSize: '24px'
                                    }}
                                />
                            </Button>

                            <Box sx={{ ml: { xs: 2, md: 8 } }}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '23px', md: '2.5rem' }, lineHeight: '1.1em', letterSpacing: '-0.08rem', fontWeight: 'bold', mt: 5 }}>
                                    FusionERP Provides Healthcare Implementation, consulting <span style={{ color: "#12715B" }}>HealthCare</span>
                                </Typography>
                                <Typography variant="body1"
                                    sx={{
                                        mt: 2,
                                        fontSize: { xs: '16px', md: '1.125rem' },
                                        opacity: '70% !important',
                                        textWrap: 'wrap'
                                    }}
                                >
                                    Time is a crucial element in our lives, helping us to  {''}
                                    <Box component="span" sx={{ display: { xs: 'inline', md: 'block' } }}>
                                        structure and organize our daily activities into good habits...
                                    </Box>
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button sx={{ textTransform: 'none', color: '#12715B', fontWeight: '700 !important' }}
                                        href="/health-care"
                                    >
                                        Read More <ArrowForwardIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>

                        <Box
                            flex="1"
                            display={{ xs: 'column', md: 'flex' }}
                            justifyContent="center"
                            sx={{
                                order: { xs: 1, md: 2 },
                                backgroundColor: '#F5EEE9',
                                padding: { xs: 2, md: 4 },
                                borderRadius: '12px',
                                mr: { xs: 2, md: 4 },
                                maxWidth: { xs: '90%', md: '33%' },  // Equal width for all boxes
                                mx: 'auto',
                                height: { xs: '300px', md: '300px' },  // Same height for all boxes
                            }}
                        >
                            <Box
                                component="img"
                                src={image4}
                                alt="firstimage"
                                sx={{
                                    width: '1220px',          // Ensures image takes up the full width
                                    height: '100%',         // Ensures image takes up the full height
                                    objectFit: 'contain',   // Ensures the entire image is visible without cropping
                                    borderRadius: '12px',   // Maintain rounded corners
                                    '&:hover': {
                                        transform: 'scale(1.1)', 
                                        transition: 'transform 0.3s ease',
                                    }
                                }}
                            />
                        </Box>



                    </Box>
                    {/* Fourth Image ends */}

                    {/* Fifth Image starts */}
                    <Box display={{ xs: 'column', md: "flex" }}
                        alignItems="center"
                        justifyContent={{
                            xs: "center",
                            md: "space-between"
                        }}
                        sx={{
                            pt: 10,
                            gap: 10
                        }}
                    >
                        <Box
                            flex="1"
                            display={{ xs: 'column', md: 'flex' }}
                            justifyContent="center"
                            sx={{
                                backgroundColor: '#F5EEE9',
                                padding: { xs: 2, md: 4 },
                                borderRadius: '12px',
                                ml: { xs: 2, md: 4 },
                                maxWidth: { xs: '90%', md: '33%' },
                                mx: 'auto',
                                height: { xs: '300px', md: '300px' },  // Same height for all boxes
                            }}
                        >
                            <Box
                                component="img"
                                src={image5}
                                alt="firstimage"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        transform: 'scale(1.1)', 
                                        transition: 'transform 0.3s ease',
                                    }
                                }}
                            />
                        </Box>

                        <Stack flex="1">
                            <Button
                                sx={{
                                    borderRadius: '12px',
                                    bgcolor: '#12715B',
                                    maxWidth: '50px',
                                    minWidth: '40px',
                                    height: '50px',
                                    padding: 0,
                                    ml: { xs: 2, md: 0 },
                                    mt: { xs: 2, md: 0 }

                                }}
                            >
                                <CloudDownloadIcon
                                    style={{
                                        color: 'whitesmoke',
                                        fontSize: '24px'
                                    }}
                                />
                            </Button>

                            <Box sx={{
                                ml: { xs: 2, md: 0 },
                            }}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '23px', md: '2.5rem' }, lineHeight: '1.1em', letterSpacing: '-0.08rem', fontWeight: 'bold', mt: 5 }}>
                                    Only Scheduling tool you need - FusionERP <span style={{ color: "#12715B" }}>Asset Management</span>
                                </Typography>
                                <Typography variant="body1"
                                    sx={{
                                        mt: 2,
                                        fontSize: { xs: '16px', md: '1.125rem' },
                                        opacity: '70% !important',
                                        textWrap: 'wrap'
                                    }}>

                                    Helps businesses efficiently manage and maintain their assets,{''}

                                    <Box component="span" sx={{ display: { xs: 'inline', md: 'block' } }}>
                                        ensuring optimal utilization and minimizing downtime.
                                    </Box>
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button sx={{ textTransform: 'none', color: '#12715B', fontWeight: '700 !important' }}
                                        href="/assets"
                                    >
                                        Read More <ArrowForwardIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    {/* Fifth Image Ends */}
                </Grid2>

                {/* Last Index Ends*/}
                <Box sx={{ mt: 10, mb: 10, position: 'relative' }}>
                    <Profile />
                </Box>

                {/* Last Index 2 */}
                <Box
                    sx={{
                        minHeight: '70vh',
                        width: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: '#F5EEE9',
                        borderRadius: '16px',
                        m: '5%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        {/* Left Image */}
                        <Box
                            sx={{
                                flex: 1,
                                height: '100%',
                                display: { xs: 'none', sm: 'block' },
                                justifyContent: 'flex-start',

                            }}
                        >
                            <img
                                src={talking}
                                alt="talking"
                                style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    width: '80%',
                                    clipPath: 'inset(0% 0% 0% 0%)',
                                    marginLeft: '-30px',
                                }}
                            />


                        </Box>

                        <Box>
                            <DarkLightImage />
                        </Box>

                        {/* Center Content */}
                        <Box
                            sx={{
                                flex: 2,
                                textAlign: 'center',
                                px: { xs: 2, md: 20 },
                                py: { xs: 2, md: 4 },
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: { xs: '1.5rem', md: '2rem' },
                                    lineHeight: '1em',
                                    letterSpacing: '-0.07rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                No long-term contracts. No catches. Simple.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '1rem', md: '1.25rem' },
                                    opacity: '70%',
                                    mt: 2,
                                }}
                            >
                                Start your 30-day free trial. Cancel anytime.
                            </Typography>
                            <Box
                                sx={{
                                    mt: 4,
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'center',
                                    gap: 2,
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    startIcon={<PlayCircleOutlineIcon />}
                                    sx={{
                                        bgcolor: 'white',
                                        color: 'black',
                                        border: '1px solid black',
                                        textTransform: 'none',
                                        padding: '8px 20px',
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                >
                                    View Demo
                                </Button>
                                <Button
                                    sx={{
                                        bgcolor: '#12715B',
                                        color: 'white',
                                        padding: '12px 20px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        borderRadius: '7px',
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                    href="/pricing"
                                >
                                    Start free trial
                                </Button>
                            </Box>
                            <Typography sx={{ mt: 2, fontSize: '13px', opacity: '70%' }}>
                                14-day trial, no credit card required.
                            </Typography>
                        </Box>

                        <Box>
                            <DarkLightImage />
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                height: '100%',
                                display: { xs: 'none', sm: 'block' },
                                justifyContent: 'flex-end',
                                mr: '-80px'


                            }}
                        >

                            <img
                                src={chatting}
                                alt="chatting"
                                style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    width: '80%',
                                    marginRight: '-50px'
                                }}
                            />
                        </Box>
                    </Box>
                </Box>


                {/* Last Index 2 End */}

                {/* Just final TOuch */}



                {/* Just find touch ends  */}
                <Box>
                    <Footer />
                </Box>
            </Container>
        </>
    );
}

export default LandingPage;
