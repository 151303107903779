import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import smileimage from '../Images/smilesimages.png'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { domain, sid } = location.state || {};

  const theme = createTheme();

  React.useEffect(() => {
    
    const timer = setTimeout(() => {
      window.location.href = `http://${domain}.fusionerp.in/desk?sid=${sid}`;
    }, 2000);

    return () => clearTimeout(timer);
  }, [domain, sid, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 8,
            textAlign: 'center'
          }}
        >
          <img src={smileimage} alt="smile" style={{ width: '150px', height: '150px' }} />
          <Typography variant="h4" sx={{ mt: 2 }}>
            Your FusionERP account has been successfully created...
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SuccessPage;
