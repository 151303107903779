import React, { useState} from 'react'
import { Box, Container, Button, Typography, Stack, Grid2, Link, Card, CardMedia, CardContent, InputAdornment, TextField,IconButton} from '@mui/material'
import DarkLightImage from './DarkLightImage'; // Assuming this is your image component
import firstimage from "../Images/post-img-3-1536x1024.webp";
import newletter from '../Images/newsletter.svg';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Footer  from './Footer';
import image1 from '../Images/erp-blog.jpg';
import image2 from '../Images/blogcrm.avif';
import image3 from '../Images/BLOGHRMS.png';
import image4 from '../Images/BLOGHealthCare.avif';
import image5 from '../Images/BlogAssets.jpg';
import {Snackbar} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import usericon from '../Images/Usericon.png';

function Blog() {

    const blogPosts = [
        {
          id:"ERP",
          imgSrc: image1,
          category: "ERP",
          title: "The transformative power of ERP in business operations",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Manjunath Sajjan",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          id:"CRM",
          imgSrc: image2,
          category: "CRM",
          title: "The value of CRM in enhancing customer relationships",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Chris Thomas",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          id:"HRMS",
          imgSrc: image3,
          category: "HRMS",
          title: "Enhancing Workforce Management with ERPNext HRMS: A Complete Guide",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Balaji",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
          id:"HealthCare",
          imgSrc: image4,
          category: "Health Care",
          title: "The Role of Technology in Transforming Healthcare Delivery and Patient Care",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Sreesandhya Sajja",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
        {
            id:"Asset Management",
          imgSrc: image5,
          category: "Asset Management",
          title: "Optimizing Business Efficiency with Effective Asset Management Strategies",
          excerpt: "She started her blog exactly six months before I launched Camels & Chocolate, and she really set the bar high for my own",
          author: "Manjunath Sajjan",
          date: "July 13, 2024",
          postUrl: "#",
          authorUrl: "#",
        },
    ];
  
   const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState('');


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    mobileNo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
        cache: 'no-cache',
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      if (result.message) {
        if (result.message.message.success === "Email ID added successfully") {
          setSnackbarMessage("Email ID added successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);

        } else if (result.message && result.message.message && result.message.message.error) {
          let errorMessage = result.message.message.error;
          setSnackbarSeverity(errorMessage === 'Email ID already exists' ? 'warning' : 'error');
          setSnackbarTitle(errorMessage === 'Email ID already exists' ? 'Warning' : 'Error');
          setSnackbarMessage(errorMessage === 'Email ID already exists' ? 'You already requested, please check your email.' : errorMessage);
          setSnackbarOpen(true);
        }
      }
      
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarTitle('Error');
      setSnackbarOpen(true);
    }
  };
    return (
        <>
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    flexDirection: 'column', 
                    mt: { xs:5,md:12},
                    position: 'relative',
                    width: '100%',
                    zIndex: 1,
                    mb: {xs:5,md:15}
                }}
            >
                {/* Left DarkLightImage */}
                <Box
                    sx={{
                        position: 'absolute',
                        left: 300,
                        top: 50,
                        width: '20%',
                        height: '100%',
                    
                    }}
                >
                    <DarkLightImage />
                </Box>

                {/* Right DarkLightImage */}
                <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: '20%',
                    height: '100%',
                
                }}
                >
                <DarkLightImage />
                </Box>

                {/* Center Text */}
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant='h2' sx={{ fontSize: { xs: '2.5em', md: '4em' }, lineHeight: { xs:'0.9em',md:'1em'}, fontWeight: 'bold' }}>
                      Insights
                  </Typography>
                </Box>
            </Box>
            
            <Container
                sx={{
                    bgcolor: '#F5EEE9',
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        flex: '1',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: { xs: '96%', sm: '50%',md:'auto' },
                        
                    }}
                >
                    <img
                        src={firstimage}
                        alt="Firstimage"
                        style={{
                            width: '100%',
                            height: '70vh',
                          objectFit: 'cover',
                            borderRadius:'12px'
                        }}
                    />
                </Box>
                <Grid2
                    direction="column"
                    sx={{
                        flex: '1',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 1,
                        mt: { xs: 2, sm: 4 },
                        width: { xs: '100%', sm: '50%' },
                    }}
                >
                    <Stack
                        direction="column"
                        sx={{ alignItems: 'center', justifyContent: 'center', gap: 4 }}
                    >
                        <Button
                            sx={{
                                p: '5px 20px',
                                bgcolor: 'rgb(18 113 91)',
                                color: 'white',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                fontSize: '15.5px',
                            }}
                        >
                            Strategy
                        </Button>
              <Typography
                            
                            sx={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                              color: '#101013',
                              fontSize: { xs:'20px',sm:'20px',md:'40px'}                              
                            }}
                        >
                            Maximizing your reach with marketing strategies
                        </Typography>
                        <Typography sx={{ gap: 2 }}>
                            <Link
                                href="#"
                                underline="hover"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  color: 'black',
                                    ml:10
                                }}
                            >
                                <img src={usericon} alt="images" style={{width:'8%',height:'8%'}} />
                                <Typography sx={{ ml: 2, mr: 1, fontWeight: 'bold' }}>
                                    Manjunath Sajjan
                                </Typography>
                                <li style={{ opacity: 0.6 }}>July 13, 2024</li>
                            </Link>
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                fontSize: '20px',
                                opacity: 0.9,
                            }}
                        >
                            She started her blog exactly six months before I launched
                            Camels & Chocolate, and she really
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                fontSize: '18px',
                                cursor: 'pointer',
                                color: 'rgb(18 113 91)',
                                '&:hover': {
                                    color: 'rgb(18 113 91)',
                                },
                            }}
                        >
                            Continue reading
                        </Typography>
                    </Stack>
                </Grid2>
            </Container>


            <Grid2
              container
              spacing={10}
              sx={{
                mt: 5,
                ml: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {blogPosts.map((post, index) => (
                <Grid2
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    maxWidth: 420,
                    flexShrink: 0,
                  }}
                >
                  <Card
                    sx={{
                      width: { xs:330,md:400},
                      height: { xs:370,md:350},
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                      borderRadius: 3,
                      textAlign: 'center',
                      padding: 2,
                      bgcolor: '#F5EEE9',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between', 
                      overflow: 'hidden',
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      {/* Image */}
                      <CardMedia
                        component="img"
                        image={post.imgSrc}
                        alt={post.title}
                        loading="lazy"
                        sx={{
                          width: '100%',
                          height: 200,
                          objectFit: 'cover', 
                          borderRadius: 2,
                          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                          cursor: 'pointer',
                          '&:hover': {
                            transform: 'scale(1.1)', 
                            transition: 'transform 0.3s ease',
                          },
                        }}
                      />

                      <Typography
                        variant="body2"
                        sx={{
                          position: 'absolute',
                          top: 8,
                          left: 8,
                          backgroundColor: '#12715B',
                          color: 'white',
                          padding: '4px 12px',
                          borderRadius: '12px',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          textTransform: 'none',
                        }}
                      >
                        {post.category}
                      </Typography>
                    </Box>

                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: '800',
                          color: 'black',
                          fontSize: '18px',
                        mb:4
                        }}
                      >
                        <Link
                          key={post.id}
                          href={`/blogpage/${post.id}`}
                          sx={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
                        >
                          {post.title}
                        </Link>
                      </Typography>

                      {/* Author and Date */}
                      <Grid2
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid2 item>
                          <Link href="/blogpage" underline="hover">
                            <img
                              src={usericon}
                              alt={post.author}
                              style={{ width: 40, height: 40, borderRadius: '50%' }}
                            />
                          </Link>
                        </Grid2>
                        <Grid2 item sx={{ display: 'flex', gap: 2 }}>
                          <Typography variant="body2" color="textSecondary">
                            <Link
                              href={`/blogpage/${post.id}`}
                              underline="hover"
                              sx={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {post.author}
                            </Link>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {post.date}
                          </Typography>
                        </Grid2>
                      </Grid2>
                    </CardContent>
                  </Card>
                </Grid2>
              ))}
            </Grid2>

            
            <Box sx ={{mt:10,mb:8}}>
                <hr/>
            </Box>

            <Container
            
              sx={{
                bgcolor: '#F5EEE9',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 4,
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 4, md: 6 },
                width: '100%',
                margin: '0 auto',
              }}
            >
              <Grid2
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  paddingRight: { md: 12 },
                  alignItems: { xs: 'center', md: 'flex-start' },
                }}
              >
                <Stack direction="column" gap={2} sx={{ pl: { xs: 0, md: 6 }, textAlign: { xs: 'center', md: 'left' } }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '28px', md: '35px' },
                      fontWeight: 'bold',
                    }}
                  >
                    Get the latest updates
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', md: '18px' },
                      opacity: 0.7,
                      maxWidth: '450px',
                    }}
                  >
                    Subscribe to get our most-popular proposal eBook and more top revenue content to help you send docs faster.
                  </Typography>
                    <Stack 
                        direction={{ xs: "column", md: "row" }} 
                        justifyContent={{ xs: 'center', md: 'flex-start' }} 
                        spacing={2} 
                    >
                    <Box>
                        <form onSubmit={handleSubmit} noValidate>
                        <Stack direction="row" spacing={2} alignItems="center"> {/* Stack TextField and Button side by side */}
                            <TextField
                            color="success"
                            variant="outlined"
                            placeholder="Your email address"
                            size="small"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                              sx={{
                                width: 350,
                                borderRadius: 2,
                                height: 46,
                                bgcolor: 'white',
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                                height: '100%',
                                padding: 0,
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove background on hover
                                },
                                '&.Mui-focused': {
                                    backgroundColor: 'transparent', // Remove background on focus
                                    borderColor: '#12715B', // Optional: Set border color to match your theme
                                },
                                },
                                '& .MuiInputBase-input': {
                                height: '100%',
                                padding: '12px',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start" sx={{ ml: 2 }}>
                                    <MailOutlineIcon sx={{ color: '#12715B' }} />
                                </InputAdornment>
                                ),
                            }}
                            />
                            <Button
                            type="submit"  
                            sx={{
                                bgcolor: '#12715B',
                                color: 'white',
                                padding: '11px 17px',
                                borderRadius: '8px',
                                fontSize: '15px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                height: 46,  
                            }}
                            >
                            Subscribe
                            </Button>
                        </Stack>
                        </form>

                        <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        message={snackbarMessage}
                        action={
                            <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                            >
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                        />
                    </Box>
                    </Stack>
                  <Typography sx={{ opacity: 0.6 }}>Don't worry we don't spam.</Typography>
                </Stack>
              </Grid2>
              <Grid2
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1,
                  ml: { md: 4 },
                  alignItems: { xs: 'center', md: 'flex-start' },
                }}
              >
                <img
                  src={newletter}
                  alt="newsletter"
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </Grid2>
            </Container>

            <Box sx = {{mt:10}}>
                <Footer />
            </Box>



        </>
  )
}

export default Blog
