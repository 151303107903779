import { Typography,Box, Grid2, Stack} from '@mui/material'
import React from 'react'
import DarkLightImage from './DarkLightImage';
import Togglebar from './Togglebar';

function HRMS({data}) {
  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column', 
          mt: 10,
          position: 'relative',
          width: '100%',
          zIndex: 1
        }}
      >
        {/* Left DarkLightImage */}
        <Box
          sx={{
            position: 'absolute',
            left: 200,
            top: 20,
            width: '20%',
            height: '100%',
          
          }}
        >
          <DarkLightImage />
        </Box>

        {/* Right DarkLightImage */}
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '20%',
            height: '100%',
          
          }}
        >
          <DarkLightImage />
        </Box>

        {/* Center Text */}
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{
            fontSize: { xs:'2.5em',md:'4em'},
            lineHeight: '1em',
            fontWeight: 'bold'
          }}>
            What separates you {''}
            <Box 
                component='span'
                sx = {{display:{
                    xs:'inline',
                    md:'block'
                }}}
            >
                from others.
            </Box>
          </Typography>
          <Typography 
              sx={{ 
              opacity: '60%', 
              fontSize: '1.125rem', 
              maxWidth: '700px',
              letterSpacing:'0.026em', 
              mt: { xs:2,md:4},
              mr: { xs:2,md:4}
              
          }}>
            We focus on helping you to make useful content more accessible with an 
            {' '}
            <Box component='span'
                sx = {{display:{
                    xs:'inline',
                    md:'block'
                }}}
            >
                utlimate goal for a good sharing profit as a content creator.
            </Box>           
          </Typography>
        </Box>
      </Box>
        {data.map((name) => (
          <Grid2
            key={name.id}
            sx={{
              display: 'flex',
              mt: 5,
              bgcolor: '#F5EEE9',
              maxWidth: {xs:'auto', md:'74%'},
              width: {xs:'95%', md:'74%'},
              ml: 'auto',
              mr: 'auto',
              justifyContent: 'space-between',
              alignItems: {xs:'left', md:'center'},
              borderRadius: '15px',
              overflow: 'hidden',
              flexDirection: { xs: 'column', md: 'row' }
            }}
          >

            <Box
              sx={{
                padding: 2,
                width: { xs:'100%',md:'50%'},
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
                order: { xs: 1, md: 2 },
              }}
            >
              <img
                src={name.image}
                alt={name.title}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '300px',
                  objectFit: 'contain',
                }}
              />
            </Box>

            <Stack
              direction="column"
              sx={{
                gap: { xs:1,md:2.5},
                padding: { xs:'1%',md:'5%'},
                width: { xs:'90%',md:'50%'},
                maxWidth: '100%',
                boxSizing: 'border-box',
                order: { xs: 2, md: 1 }, 
                ml:{xs:3,md:0}
              }}
            >
              <Typography
                sx={{
                  fontWeight: 1000,
                  color: '#12715B',
                  fontSize: { xs:'1rem',md:'1.2rem'},
                }}
              >
                {name.number}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {xs:'1.7rem',md:'3rem'},
                  lineHeight: '1.2em',
                  letterSpacing: '-0.08rem',
                  fontWeight: 'bold',
                  fontFamily: 'Roboto',
                }}
              >
                {name.title}
              </Typography>
              <Typography
                sx={{
                  mt:{xs:1,md:0},
                  width: { xs:'100%',md:'90%'},
                  fontSize: { xs:'14px',md:'18px'},
                  opacity: 0.8,
                  color: 'rgb(33 37 41 / 70%)',
                  letterSpacing: '0.005rem',
                }}
              >
                {name.description}
              </Typography>
            </Stack>

          </Grid2>
        ))}
      
      <Box sx = {{mt:2}}>
        <Togglebar />
      </Box>

    </>
  )
}

export default HRMS
