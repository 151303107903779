import { Typography, Button, Container, Card, CardContent, CardActions,Grid2,IconButton} from '@mui/material';

import { useState } from 'react';
import Box from "@mui/material/Box"
import CheckIcon from '@mui/icons-material/Check';
import money from "../Images/money.svg"

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Footer  from './Footer';
import PricingProfile from './PricingProfile';
import { useNavigate } from 'react-router-dom';

const FusionerpCrmScription = () => {
 const [selectedButton,setSelectedButton] = useState('monthly');
  
  const handleClick = (button) =>{
    setSelectedButton(button);
  }

  const questions = [
    { question: "Is there a limit to sending emails?", answer:  "Yes, if you use  email account/ SMTP there is a limit of 500 emails per month.With FusionERP you can easily be configured with any of your email accounts using SMTP and using this you can send Unlimited Emails.There is no limit to emails if you send emails using your own account."},
    { question: "Is there a limit to the storage?", answer: "Yes, there is a storage limit of 25GB per site. The storage limit can be extended with an additional cost of $30 / 25 GB / Month" },
    { question: "Do you have any partnership programs?", answer:  "Yes, we do. To become our partner visit at partner.fusionerp.in" },
    { question: "Will I get access to all future updates and features?", answer: "Of course! If you purchase our deal now, you will get all future updates and features without any additional costs" },
    { question: "What does 'unlimited users' mean?", answer: "You can add any number of users to your plan without paying for additional users." },
  ];

  const [openAnswers, setOpenAnswers] = useState(Array(questions.length).fill(false));

  const toggleAnswer = (index) => {
    setOpenAnswers((prevOpenAnswers) =>
      prevOpenAnswers.map((open, i) => (i === index ? !open : open))
    );
  };

  const firstcard = [
    "Real-time tracking and notifications",
    "Real-time analytics",
    "Drag and drop templates",
    "Project Management",
    "24/7 email and chat support"
  ]

  const secondcard = [
    "ERP and Zapier integrations",
    "Content reporting",
    "Unlimited team workspaces",
    "Approval workflows",
    "Salesforce integration*"
  ]

  const thirdCard = [
    "Unlimited files uploads",
    "Real-time tracking and notifications",
    "User performance",
    "SSO support and custom user roles",
    "Bulk send & Forms*"

  ]
    
    const navigate = useNavigate();

    const handlepressclick = (plan,price) => {
        navigate(`/signup?defaultValue=ERPNEXT&plan=${encodeURIComponent(plan)}&price=${price}`);
    };

    return (

        <>
          <Container maxWidth={false}>
                  <Box
                      sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid black',
                          maxWidth: '300px',
                          height:'44px',
                          padding: 0,
                          margin: 'auto',
                          borderRadius: '12px',
                      }}
                      >
                      <Button
                          sx={{
                          color: selectedButton === 'monthly' ? 'white' : 'black',
                          textTransform: 'none',
                          fontSize: '16px',
                          borderRadius: '10px',
                          margin: 0,
                          padding: '5px 45px',
                          width: 'auto',
                          display: 'inline-flex',
                          boxSizing: 'border-box',
                          backgroundColor: selectedButton === 'monthly' ? '#12715B' : 'transparent',
                          transform: selectedButton === 'monthly' ? 'scale(0.98)' : 'none',
                          }}
                          onClick={() => handleClick('monthly')} 
                      >
                          Monthly
                      </Button>
                      <Button
                          sx={{
                              color: selectedButton === 'yearly' ? 'white' : 'black',
                              textTransform: 'none',
                              fontSize: '16px',
                              borderRadius: '10px',
                              margin: 0,
                              padding: '5px 45px',
                              width: 'auto',
                              display: 'inline-flex',
                              boxSizing: 'border-box',
                              backgroundColor: selectedButton === 'yearly' ? '#12715B' : 'transparent',
                              transform: selectedButton === 'yearly' ? 'scale(0.98)' : 'none',                 
                          }}
                          onClick={() => handleClick('yearly')} 
                      >
                          Yearly
              </Button>
                  </Box>


          </Container>
      
          <Box
              
              sx={{
                  mt: 10,
                  left: 0,
                  right: 0, 
                  padding: 0,
                  position: 'absolute'
              }}
          >
              <Grid2 container spacing={4} sx = {{justifyContent:'center'}} >
                  {/* Card 1 */}
                  <Grid2 xs={12} sm={6} md={4} lg={8} >
                  <Card sx = {{
                      maxWidth: {xs:370,md:390},
                      borderRadius:'12px',
                      bgcolor:'#F5EEE9',
                      justifyContent:'flex-end'
                  }}>
                      <CardContent sx = {{pl:{xs:2,md:5.2},pt:2,mt:{xs:1,md:5}}}>
                      <Typography variant="h5" component="div" 
                          sx = {{fontWeight:'bold',mb:2}}
                      >
                          Essentials
                      </Typography>
                      <Typography variant="body2" sx = {{
                          fontSize:'14px',
                          letterSpacing:{xs:'0.12rem',md:'0.100rem'},
                          opacity:'70% !important'
                          }}>
                          For creating impressive tools that generate results.
                      </Typography>

                      <Typography sx = {{mt:2.5,fontSize:'2.5rem',
                          fontWeight:'800'
                      }}>
                          ${selectedButton === 'monthly' ? '19' : '49'}  USD
                      </Typography>

                      <Typography sx = {{
                          opacity:'70%',
                          mt:-1
                      }}>
                          Seat per month,2 seats max
                      </Typography>
                      </CardContent>
                      <CardActions sx = {{
                      mt:1,
                      justifyContent:'center'}}>
                      <Button
                          sx = {{
                          padding:'12px 96px',
                          bgcolor:'#12715B',
                          textTransform:'none',
                          color:'white',
                          fontSize:'16px',
                          fontWeight:'bold',
                          width:{xs:'100%',md:'auto'}
                                    }}
                                    onClick={() => handlepressclick('TeamPlan', `${selectedButton === 'monthly' ? '19' : '49'}`)}
                      >Get Started</Button>
                      
                      </CardActions>
                      <Typography sx = {{textAlign:'center',opacity:'70%'}}>
                          No credit card required
                      </Typography>
                      
                      <CardContent sx={{ pl: {xs:2,md:4}, pt: 2, mt: {xs:2,md:4},mb:2 }}>
                          <Box>
                          <Typography sx={{ ml:1,fontSize: '18px', fontWeight: '800' }}>
                              Key features:
                          </Typography>
                          {firstcard.map((item, index) => (
                              <Box key={index} 
                              sx={{ 
                                  ml:1.3, 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  mt: 2
                              }}
                              >
                              <Box 
                                  sx={{
                                  width: 15, 
                                  height: 15,
                                  backgroundColor: '#12715B',
                                  borderRadius: '50%', 
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mr: 1,
                                  }}
                              >
                                  <CheckIcon sx={{ fontSize: '16px', color: 'white' }} />
                              </Box>
                              <Typography sx = {{fontSize:'14px'}}>
                                  {item}
                              </Typography>
                              </Box>
                          ))}
                          </Box>
                      </CardContent>

                  </Card>
                  </Grid2>

              {/* Card 2 */}
              <Grid2 xs={12} sm={6} md={4}>
                  <Card sx={{
                      width: {xs:370,md:390},
                      borderRadius: '12px',
                      bgcolor: '#F5EEE9',
                      justifyContent: 'flex-end',
                      position: 'relative'
                  }}>
                      <Box 
                      sx={{
                          position: 'absolute', 
                          top: 16,            
                          right: 16,
                          bgcolor: '#12715B', 
                          color: 'white',  
                          padding: '6px 12px', 
                          borderRadius: '7px', 
                          maxWidth: '12%',
                          textAlign: 'center',
                          fontSize: '15px'
                      }}
                      >
                      Popular
                      </Box>
                      
                      <CardContent sx = {{pl:{xs:2,md:5.2},pt:2,mt:{xs:1,md:5}}}>
                      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                          Business
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '14px', letterSpacing:{xs:'0.12rem',md:'0.100rem'}, opacity: '70% !important' }}>
                          For seamless integrations and sending tools in bulk.
                      </Typography>

                      <Typography sx={{ mt: 2.5, fontSize: '2.5rem', fontWeight: '800' }}>
                          ${selectedButton === 'monthly' ? '12' : '32'}  USD
                      </Typography>

                      <Typography sx={{ opacity: '70%', mt: -1 }}>
                          Seat cost per month
                      </Typography>
                      </CardContent>

                      <CardActions sx={{ mt: 1, justifyContent: 'center' }}>
                      <Button
                          sx={{
                          padding: '12px 96px',
                          bgcolor: '#12715B',
                          textTransform: 'none',
                          color: 'white',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          width:{xs:'100%',md:'auto'}
                                    }}
                                    onClick={() => handlepressclick('Business', `${selectedButton === 'monthly' ? '12' : '32'}`)}
                                >
                                    
                          Get Started
                      </Button>
                      </CardActions>

                      <Typography sx={{ textAlign: 'center', opacity: '70%' }}>
                      No credit card required
                      </Typography>

                      <CardContent sx={{ pl: {xs:2,md:4}, pt: 2,mt: {xs:2,md:4}, mb: 2 }}>
                      <Box>
                          <Typography sx={{ ml: 1, fontSize: '18px', fontWeight: '800' }}>
                          Everything in Essentials, plus:
                          </Typography>
                          {secondcard.map((item, index) => (
                          <Box key={index} sx={{ ml: 1.3, display: 'flex', alignItems: 'center', mt: 2 }}>
                              <Box
                              sx={{
                                  width: 15,
                                  height: 15,
                                  backgroundColor: '#12715B',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mr: 1,
                              }}
                              >
                              <CheckIcon sx={{ fontSize: '16px', color: 'white' }} />
                              </Box>
                              <Typography sx={{ fontSize: '14px' }}>
                              {item}
                              </Typography>
                          </Box>
                          ))}
                      </Box>

                      <Box sx={{ 
                          position: 'absolute', 
                          top:'80%',
                          bottom: 40,         
                          right: 16,
                          color: 'white',  
                          padding: '6px 12px', 
                          borderRadius: '7px', 
                          maxWidth: '12%',
                          textAlign: 'center',
                          fontSize: '15px'
                      }}>
                          <img src={money} 
                          alt = "money" 
                          style = {{
                              maxWidth:'250%',
                              height:'auto',
                              marginLeft:'-55px'
                          }}
                          />
                      </Box>
                      </CardContent>
                  </Card>
                  </Grid2>



                  {/* Card 3  */}
                  <Grid2 xs={12} sm={6} md={4}>
                  <Card sx = {{
                      width: {xs:370,md:390},
                      borderRadius:'12px',
                      bgcolor:'#F5EEE9',
                      justifyContent:'flex-end'
                  }}>
                      <CardContent sx = {{pl:{xs:2,md:5.2},pt:2,mt:{xs:1,md:5}}}>
                      <Typography variant="h5" component="div" 
                          sx = {{fontWeight:'bold',mb:2}}
                      >
                          Enterprise
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '14px', letterSpacing:{xs:'0.12rem',md:'0.100rem'}, opacity: '70% !important' }}>
                          For large companies with complex Tool workflows.
                      </Typography>

                      <Typography sx = {{mt:2.5,fontSize:'2.5rem',
                          fontWeight:'800'
                      }}>
                          Let's talk
                      </Typography>

                      <Typography sx = {{
                          opacity:'70%',
                          mt:-1
                      }}>
                          Per‑seat or per‑tool pricing
                      </Typography>
                      </CardContent>
                      <CardActions sx = {{
                      mt:1,
                      justifyContent:'center'}}>
                      <Button
                          sx = {{padding:'12px 96px',bgcolor:'#212529',
                          textTransform:'none',
                          color:'white',
                          fontSize:'16px',
                          fontWeight:'bold',
                          width:{xs:'100%',md:'auto'}
                                    }}
                                    onClick={() => navigate('/contactus')}
                      >Contact sales</Button>
                      
                      </CardActions>
                      <Typography sx = {{textAlign:'center',opacity:'70%'}}>
                          Respond within 24 hrs max
                      </Typography>
                      
                      <CardContent sx={{ pl: {xs:2,md:4}, pt: 2,mt: {xs:2,md:4}, mb: {xs:0,md:2} }}>
                          <Box>
                          <Typography sx={{ fontSize: '16px',ml:1, fontWeight: '800' }}>
                              Everything in Business, plus:
                          </Typography>
                          {thirdCard.map((item, index) => (
                              <Box key={index} 
                              sx={{ 
                                  ml:1.3, 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  mt: 2
                              }}
                              >
                              <Box 
                                  sx={{
                                  width: 15, 
                                  height: 15,
                                  backgroundColor: '#12715B',
                                  borderRadius: '50%', 
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mr: 1,
                                  }}
                              >
                                  <CheckIcon sx={{ fontSize: '14px', color: 'white' }} />
                              </Box>
                              <Typography sx = {{fontSize:'14px'}}>
                                  {item}
                              </Typography>
                              </Box>
                          ))}
                          </Box>
                      </CardContent>

                  </Card>
                  </Grid2>
                  {/* Card 3 ends */}
              </Grid2>
          </Box>

          <Box sx={{ textAlign: 'center', justifyContent: 'center',mt:100}}>
              <Typography sx = {{opacity:"70%",mt:{xs:2,md:5}}}>
                  Prices exclude any applicable taxes.
              </Typography>
              <Typography 
                  sx = {{
                      mt:10,
                      fontSize:'2.5em',
                      lineHeight:'1.1em',
                      letterSpacing:'-0.08rem',
                      fontWeight:'bold',
                      mb:{xs:4,md:5}
                  }}
              >
                  Frequently asked questions:
              </Typography>
          <Container
            sx={{
              ml:{xs:0, md:34},
              bgcolor: '#F5EEE9',
              width: {xs:'auto',md:'55.1%'},
              padding:{xs:1,md:5},
              borderRadius:'15px'
            }}
          >
        {questions.map((item, index) => (
          <Box key={index} mb={2}>
            {/* Question and Toggle Icon */}
            <Box display="flex" justifyContent="space-between">
              <Typography 
                variant="h6" 
                onClick={() => toggleAnswer(index)} 
                sx={{ 
                  mt:{xs:1,md:0},
                  fontSize:{xs:'12px',md:'18px'},
                  cursor: 'pointer', 
                  color: '#333',
                  ml:3,
                  fontWeight:'bold',
                  
                }}
              >
                {item.question}
              </Typography>
              
              <Box sx={{ mr: 4 }}>
                <IconButton onClick={() => toggleAnswer(index)}>
                  {openAnswers[index] ? <CloseIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                </IconButton>
              </Box>
            </Box>

            {/* Answer, displayed below the question */}
            {openAnswers[index] && (
              <Typography variant="body1" color="textSecondary" sx = {{ml: 3.5, 
                textAlign: 'left', 
                mt: 1, 
                wordWrap: 'break-word', 
                maxWidth: 'calc(100% - 100px)'}}>
                {item.answer}
              </Typography>
            )}
          </Box>
        ))}
          </Container>
          </Box>

          <Box sx={{
              mt: 10,
              left: 0,
              right: 0, 
              padding: 0, position: 'absolute'
          }}>
              <PricingProfile />
              <Footer />
          </Box>           
        </>
    )
}


export default FusionerpCrmScription;
