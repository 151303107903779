import * as React from 'react';
import Stack from '@mui/material/Stack';
import SignInCard from './SignInCard';

export default function Signup() {
  return (
    <Stack
      direction="column"
      component="main"
      sx={{
        justifyContent: 'center',
        height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
        marginTop: 'max(10px - var(--template-frame-height, 0px), 0px)',
        minHeight: '100%',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 4, sm: 6, md: 12 }, 
          p: 2,
          mx: 'auto',
          width: '100%',
        }}
      >
       
        <SignInCard />
      </Stack>
    </Stack>
  );
}
