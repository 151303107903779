import React,{useState} from 'react'
import { Box,Typography,Container, IconButton,Stack, Button} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import b1 from "../Images/brand-01.svg";
import b2 from "../Images/brand-02.svg";
import b3 from "../Images/brand-03.svg";
import b4 from "../Images/brand-04.svg";
import b5 from "../Images/brand-05.svg";

import chart from "../Images/charts.svg";
import Footer from './Footer';

function Togglebar() {
  const questions = [
    { question: "Is there a limit to sending emails?", answer:  "Yes, if you use  email account/ SMTP there is a limit of 500 emails per month.With FusionERP you can easily be configured with any of your email accounts using SMTP and using this you can send Unlimited Emails.There is no limit to emails if you send emails using your own account."},
    { question: "Is there a limit to the storage?", answer: "Yes, there is a storage limit of 25GB per site. The storage limit can be extended with an additional cost of $30 / 25 GB / Month" },
    { question: "Do you have any partnership programs?", answer:  "Yes, we do. To become our partner visit at partner.fusionerp.in" },
    { question: "Will I get access to all future updates and features?", answer: "Of course! If you purchase our deal now, you will get all future updates and features without any additional costs" },
    { question: "What does 'unlimited users' mean?", answer: "You can add any number of users to your plan without paying for additional users." },
  ];
    
  const[openAnswers,setOpenAnswers] = useState(Array(questions.length).fill(false))

  const toggleAnswer = (index) =>{
    setOpenAnswers((prevOpenAnswers) => 
      prevOpenAnswers.map((open,i) => (i === index ? !open : open))
    )
  }
    
    const brand_images = [
        { id: 1, image: b1 },
        { id: 2, image: b2 },
        { id: 3, image: b3 },
        { id: 4, image: b4 },
        { id: 5, image: b5 },
        
    ]
    
  return (
    <>
        <Box sx = {{textAlign:'center',justifyContent:'center'}}>
            
            <Typography 
              sx = {{
                mt:10,
            fontSize: { xs:'2.3em',md:'2.5em'},
                lineHeight:'1.1em',
                letterSpacing:'-0.08rem',
                fontWeight:'bold',
                mb:{xs:4,md:5}
              }}
            >
              Frequently asked questions:
            </Typography>
            <Container
              sx={{
               justifyContent:'center',
                bgcolor: '#F5EEE9',
                width: {xs:'auto',md:'55.1%'},
                padding:{xs:1,md:5},
                      borderRadius: '15px',
                mb:10
              }}
            >
          {questions.map((item, index) => (
            <Box key={index} mb={2}>
              {/* Question and Toggle Icon */}
              <Box
                display="flex"
                justifyContent="space-between">
                <Typography 
                  variant="h6" 
                  onClick={() => toggleAnswer(index)} 
                  sx={{ 
                    mt:{xs:1,md:0},
                    fontSize:{xs:'12px',md:'18px'},
                    cursor: 'pointer', 
                    color: '#333',
                    ml: { xs:2,md:3},
                    fontWeight:'bold',
                    
                  }}
                >
                  {item.question}
                </Typography>
                
                <Box sx={{ mr: 4 }}>
                  <IconButton onClick={() => toggleAnswer(index)}>
                    {openAnswers[index] ? <CloseIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                  </IconButton>
                </Box>
              </Box>

              {/* Answer, displayed below the question */}
              {openAnswers[index] && (
                <Typography variant="body1" color="textSecondary"
                  sx={{
                    ml: { xs:2,md:3.5}, 
                    textAlign: 'left', 
                    mt: 1, 
                    wordWrap: 'break-word', 
                  maxWidth: 'calc(100% - 100px)'}}>
                  {item.answer}
                </Typography>
              )}
            </Box>
          ))}
            </Container>
          </Box>

        {/* <Box sx = {{textAlign:'center',mt:5}}>
            <Typography sx = {{fontSize:'1.25em',lineHeight:'1.2em',fontWeight:'bold',mb:{xs:3,md:10}}}>
                Trusted by well-known brands.
            </Typography>

            <Box gap = {1} sx = {{display:'flex',alignItems:'center',justifyContent:'center',mb:{xs:3,md:10}}}>
                {brand_images.map((images) =>(
                    <Typography>
                        <img src = {images.image} alt = "brand" style={{width:'70%'}} />
                    </Typography>
                ))}
            </Box>
        </Box>   */}

        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
              sx={{
                mt:{xs:2,md:0},
                width: {xs:'95%',md:'80%'},
                height: { xs:'47ch',md:'35ch'},
                display: 'flex',
                justifyContent: 'space-evenly',
                bgcolor: '#F5EEE9',
                borderRadius: '16px',
                mb: '5%',
              }}
            >
              <Stack
                  direction={{
                    xs: 'column',
                    md: "row"
                  }}
                  sx={{
                      justifyContent: {xs:'center',md:'space-between'},
                      width: '100%',
                      pt: {xs:2,md:4},
                      pb: {xs:2,md:4},
                      ml: { xs: 7, md: 10 },
                      mt:{xs:2,md:4}
                      
                  }}
              >
                  {/* Logo at flex-start */}
                  <Box>
                      <Typography
                          variant="h2"
                          sx={{
                            fontSize: { xs:'2.4rem',md:'2.9rem'},
                              lineHeight: '1.1em',
                              letterSpacing: '-0.08rem',
                              fontWeight: 'bold',
                          
                          }}
                      >
                          Create your site {''}
                        </Typography>
                        <Button sx = {{padding:'15px',bgcolor:'#12715B',color:'white',mt:2,fontWeight:'bold',textTransform:'none',fontSize:'18px'}}>
                            Try FusionErp today
                        </Button>
                        <Typography sx = {{mt:2,opacity:0.6}}>
                            14-day trial, no credit card required.
                        </Typography>
                  </Box>

                  {/* App store icons at flex-end */}
                  <Box
                  sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mr: {xs:0,md:3},mt:{xs:2,md:0}}}
                  >
                      <Typography sx = {{mr:10}}>
                          <img src={chart} alt="chart" />
                      </Typography>
                  </Box>
              </Stack>
            </Box>
          </Box> 
          
        <Footer />
    </>
  )
}

export default Togglebar