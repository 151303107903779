// src/data.js
import f1 from "../Images/RecruitmentManagement.png"
import f2 from "../Images/timeandattendance.png"
import f3 from "../Images/LeaveManagement.png"
import f4 from "../Images/COREHR.png"

import c1 from "../Images/crm1.png";
import c2 from "../Images/crm2.png";
import c3 from "../Images/crm3.png";
import c4 from "../Images/crm4.png";

import e1 from "../Images/erp1.svg";
import e2 from "../Images/erp2.svg";
import e3 from "../Images/erp3.svg";
import e4 from "../Images/erp4.svg";

import a1 from "../Images/a1.png"

import h1 from "../Images/h1.png";
import h2 from "../Images/h2.png";
import h3 from "../Images/h3.png"

const data = {
  HRMS: [
    {
      id: 1,
      number:"01.",
      title:'Recruitment Management',
      description: 'Streamline your hiring process with our Applicant Tracking System (ATS). Integrate seamlessly with platforms like LinkedIn, Naukri, and Glassdoor to source top talent efficiently. Optimize candidate experience and improve recruitment outcomes with advanced analytics and automation.',
      image: f1,
    },
    {
      id: 2,
      number:"02.",
      title:'Time and Attendance',
      description: 'Track employee time and attendance effortlessly. Our system provides accurate timekeeping, ensuring compliance and reducing payroll errors. Gain real-time insights into workforce attendance patterns and manage shifts with ease.',
      image: f2,
    },
    {
      id: 3,
      number:"03.",
      title:'Leave Management',
      description: 'Simplify leave management with our intuitive system. Employees can apply for leave online, and managers can approve or reject requests with a single click. Keep track of leave balances, generate reports, and ensure smooth workflow without disruptions.',
      image: f3,
    },
    {
      id: 4,
      number:"04.",
      title:'Core HR',
      description: 'Manage your employee data effectively with our comprehensive Core HR module. Maintain detailed employee records, streamline onboarding processes, and ensure data accuracy. Enhance your HR operations with centralized employee information.',
      image: f4,
    }
  ],
  CRM: [
     {
      id: 1,
      number:"01.",
      title:'Powerful Lead Management',
      description: 'FusionERP CRM is a cloud-based solution designed to help businesses,manage sales opportunities and enhance work automation',
      image: c1,
    },
    {
      id: 2,
      number:"02.",
      title:'Accurate CRM Analytics',
      description: `Evaluate organization's customer data for streamlining sales processes and evaluating opportunity and lead generating funnels. Measure the effectiveness of ongoing campaigns, profitability of current deals, and predict the future opportunity-generating models.`,
      image: c2,
    },
    {
      id: 3,
      number:"03.",
      title:'Interactive Workflow Automation',
      description: `Intelligently automate your team's time-consuming chores so they can focus on more essential responsibilities with efficient automation tools. Change client status, generate immediate emails for query resolution, and stay connected with customers with smooth drip campaigns through newsletters, and automated posts for Twitter and LinkedIn.`,
      image: c3,
    },
    {
      id: 4,
      number:"04.",
      title:'Seamless System Configuration',
      description: 'Easily configure unique views, connect information, test modifications into the system, and even select the language and currency as per the needs with custom fields, buttons, and layouts. Integrate information from multiple sources and configure your profile the way you desire',
      image: c4,
    }
  ],
  ERP: [
     {
      id: 1,
      number:"01.",
      title:'HR, Employee Lifecycle and Payroll Management',
      description: 'Automate onboarding, tax, and salary-related processes in one click. Perform all human resource management functions, set up employee details with their designation, create salary grades and seamlessly generate salary slips.',
      image: e1,
    },
    {
      id: 2,
      number:"02.",
      title:'Financial Management',
      description: `Simplify financial operations that boost workflow automation, reduce inefficiencies, and motivate more profitable decision-making. Analyze your business decisions with real-time reporting and save time so you can steer strategically.`,
      image: e2,
    },
    {
      id: 3,
      number:"03.",
      title:'Buying and Warehouse Management',
      description: `Maintain custom warehouses by planning, managing, executing, monitoring, and reporting on all related processes. Understand purchase trends and buying processes. Adapt to the unique requirements of each industry, including manufacturing, retail, and food production.`,
      image: e3,
    },
    {
      id: 4,
      number:"04.",
      title:'Project Management and Quality Checks',
      description: `Discover project and task management with complete integration of billing and costing. Create a timesheet based on actual time spent on a task. Track the profitability of a project to ensure that you don't overspend and maintain quality with goals, tracking, and feedback.`,
      image: e4,
    }
  ],

  Assets: [
    {
      id: 1,
      number:"01.",
      title:'Maximize Your Asset Utilization',
      description: 'Fusion Asset helps businesses efficiently manage and maintain their assets, ensuring optimal utilization and minimizing downtime.',
      image: a1,
    },
  ],

  HEALTHCARE: [
    {
      id: 1,
      number:"01.",
      title:'Healthcare with FusionERP',
      description: 'Revolutionizing Healthcare with FusionERP Streamlined Operations, Improved Care and Cost Efficiency"',
      image: h1,
    },
    {
      id: 2,
      number:"02.",
      title:'Enhanced Healthcare Management Solutions',
      description: 'Streamline healthcare management with improved data reporting, automated supply chain, and efficient patient visit processes.',
      image: h2,
    },
    {
      id: 3,
      number:"03.",
      title:'Enhanced Efficiency and Customization in Healthcare',
      description: 'Boost efficiency with automated inter-departmental processes, seamless real-time communication, and easy customization to fit specific healthcare needs. Frappe Health enhances productivity and adapts to unique requirements.',
      image: h3,
    },
  ]
};

export default data;
